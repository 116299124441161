//Choose.js
import React from 'react';
import NewProjectCard from './NewProjectCard';
import ExistingProjectCard from './ExistingProjectCard';
import './Choose.css';  // Import the CSS file

const Choose = () => {
    return (
        <div className="choose-container">
            <NewProjectCard />
            <ExistingProjectCard />
        </div>
    );
};

export default Choose;