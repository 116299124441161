// RunProjectCard.js

import React, { useState } from 'react';
import DeploymentCard from './DeploymentCard';
import './RunProjectCard.css';
import { FaChevronUp } from 'react-icons/fa'; // import the required icons
import ConfigCard from './ConfigCard'; 
import { useContext } from 'react';
import { ProjectContext } from './ProjectContext';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional for default styling




const RunProjectCard = ({ runProject, deploymentSteps, hasRunStarted, deleteProject }) => {
    const [expanded, setExpanded] = useState(false);
    const { project, configs, setConfigs, setSnackbarMessage, deployment } = useContext(ProjectContext);
    const [configModalOpen, setConfigModalOpen] = useState(false);



    // Determine if all steps are completed
    const allStepsCompleted = deploymentSteps.every(step => step.status === 'Completed');

    const toggleExpanded = () => {
        setExpanded(prev => !prev);
    };

    const handleConfigSave = (configName, updatedConfig) => {
        setConfigs(prevConfigs => {
            const index = prevConfigs.findIndex(config => config.ConfigName === configName);
            if (index !== -1) {
                const newConfigs = [...prevConfigs];
                newConfigs[index].ConfigValue = JSON.stringify(updatedConfig);
                return newConfigs;
            } else {
                return [...prevConfigs, { ConfigName: configName, ConfigValue: JSON.stringify(updatedConfig) }];
            }
        });
    };
    

    const parseConfigValueSafe = (value) => {
        console.log("Attempting to parse:", value);
        try {
            return JSON.parse(value);
        } catch (error) {
            console.error('Failed to parse:', value);
            return null; // or some default value
        }
    };

    const areConfigsConfirmed = () => {
        const slackConfig = configs.find(config => config.ConfigName === 'SlackConfig');
        const aiConfig = configs.find(config => config.ConfigName === 'AiConfig');
        return slackConfig?.ConfigStatus === 'Confirmed' && aiConfig?.ConfigStatus === 'Confirmed';
    };

    const getTooltipMessage = () => {
        if (!areConfigsConfirmed()) {
            return "Confirm your settings, then press the run project button to deploy your customized application.";
        } else if (allStepsCompleted) {
            return "Deployment is already completed.";
        } else {
            return "Click to deploy your project.";
        }
    };
    
    

    return (
        <div className="run-project-card">
            <div className="run-project-header" onClick={toggleExpanded}>
                <FaChevronUp className={`chevron-icon ${expanded ? 'rotated' : ''}`} size={24} />
                <span className="run-project-title">Deploy Your Project</span>
            </div>
                

            {expanded && (
                <>
                    <div className="config-card-container">
                        {configs
                            .filter(config => ['SlackConfig', 'AiConfig'].includes(config.ConfigName))
                            .map((config) => (
                                <ConfigCard
                                    key={config.ConfigID}
                                    configName={config.ConfigName}
                                    configValue={parseConfigValueSafe(config.ConfigValue)}
                                    configStatus={config.ConfigStatus}
                                    configID = {config.ConfigID}
                                    fileID={config.FileID}
                                    onSave={handleConfigSave}
                                    setSnackbarMessage={setSnackbarMessage}
                                    configs={configs}
                                    setConfigs={setConfigs}
                                />
                            ))
                        }
                    </div>

                    <Tippy content={getTooltipMessage()}>
                        <div style={{ display: 'inline-block', cursor: 'not-allowed', color: '#007BFF', padding: '10px 0px 20px 0px' }} >
                            <button 
                                onClick={runProject} 
                                disabled={!areConfigsConfirmed() || allStepsCompleted}
                            >
                                {allStepsCompleted ? "Deployment Completed" : "Run Project"}
                            </button>
                        </div>
                    </Tippy>




                    {['Deployed', 'Deployment Error'].includes(project.ProjectStatus) && (
                        <button onClick={deleteProject}>
                            Delete Project
                        </button>
                    )}



                    {hasRunStarted && (
                        <>
                            <div className="run-project-step-counter">
                                {`${deploymentSteps.filter(step => step.status === 'Completed').length}/${deploymentSteps.length}`} deployment steps completed
                            </div>
                            {deploymentSteps.map((step, index) => (
                                <DeploymentCard key={index} step={step} />
                            ))}
                        </>
                    )}

                </>
            )}
        </div>
    );
};

export default RunProjectCard;


