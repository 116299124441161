// Documents.js
import React, { useState, useEffect, useContext, useRef } from 'react';
import './Documents.css';
import './Industry.css'; 
import api from './api';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; 
import CircularProgress from '@mui/material/CircularProgress';
import BrainstormContext from './BrainstormContext';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { ProjectContext } from './ProjectContext';

const Documents = () => {
    const [documents, setDocuments] = useState([]);
    const [loadingDocuments, setLoadingDocuments] = useState(true);
    const [activeCardIndex, setActiveCardIndex] = useState(null);
    const [editingCardIndex, setEditingCardIndex] = useState(null);
    const [tempEditingName, setTempEditingName] = useState('');
    const [tempEditingDescription, setTempEditingDescription] = useState('');
    const previousProjectRef = useRef();
    const { continueButtonRef, setSelectedData } = useContext(BrainstormContext);
    const { project, setProject } = useContext(ProjectContext);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (continueButtonRef.current && continueButtonRef.current.contains(event.target)) {
                return;
            }
    
            if (!event.target.closest('.industry-card')) {
                setActiveCardIndex(null);
                setProject(prev => ({ ...prev, ProjectDocuments: null, ProjectDocumentDescriptions: null }));
                setSelectedData(null);
            }
        }
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, []);

    useEffect(() => {
        if (previousProjectRef.current !== project.Industry) {
            const fetchDocuments = async () => {
                try {
                    const response = await api.post('/api/generate_documents', {
                        projectID: project.ProjectID,
                        industryName: project.Industry,
                        industryDescription: project.ProjectCompanyDescription,
                        jobName: project.ProjectJob,
                        jobDescription: project.ProjectJobDescription,
                        companyType: project.ProjectCompany
                    });
                    setDocuments(response.data.documents);
                } catch (error) {
                    console.error('Error fetching documents:', error);
                } finally {
                    setLoadingDocuments(false);
                }
            };
            fetchDocuments();
        }
        previousProjectRef.current = project.Industry;
    }, [project]);

    const handleCardClick = (index) => {
        if (activeCardIndex === index) {
            setActiveCardIndex(null);
            setProject(prev => ({ ...prev, ProjectDocuments: null, ProjectDocumentDescriptions: null }));
            setSelectedData(null);
        } else {
            setActiveCardIndex(index);
            setProject(prev => ({ 
                ...prev, 
                ProjectDocuments: documents[index].name,
                ProjectDocumentDescriptions: documents[index].description
            }));
            setSelectedData(true);
        }
    };

    const handleToggleEditMode = (index, document) => {
        if (editingCardIndex === index) {
            setEditingCardIndex(null);
            setTempEditingName('');
            setTempEditingDescription('');
        } else {
            setEditingCardIndex(index);
            setTempEditingName(document.name);
            setTempEditingDescription(document.description);
        }
    };

    const handleSaveEdits = async (index) => {
        documents[index].name = tempEditingName || documents[index].name;
        documents[index].description = tempEditingDescription || documents[index].description;
        setDocuments([...documents]);
        setEditingCardIndex(null);
        setTempEditingName('');
        setTempEditingDescription('');
    };

    if (loadingDocuments) {
        return (
            <div className="industry-container">
                <div className="spinner-container">
                    <CircularProgress />
                </div>
            </div>
        );
    }

    console.log('Project Context in Documents.js:', project);

    return (
        <div className="industry-container">
            {documents.map((document, index) => (
                <div 
                    key={index} 
                    className={`industry-card ${activeCardIndex === index ? 'active' : ''} ${editingCardIndex === index ? 'editing' : ''}`} 
                    onClick={() => handleCardClick(index)}
                >
                    <div className="card-header">
                        {editingCardIndex === index ? 
                            <>
                                <input 
                                    value={tempEditingName || document.name} 
                                    onChange={e => setTempEditingName(e.target.value)}
                                    onBlur={() => handleSaveEdits(index)}
                                    onKeyDown={e => {
                                        if (e.key === 'Enter') handleSaveEdits(index);
                                    }}
                                    onClick={e => e.stopPropagation()}
                                />
                                <span className="edit-icons">
                                    <Tippy content="Save">
                                        <SaveIcon onClick={(e) => { e.stopPropagation(); handleSaveEdits(index); }} />
                                    </Tippy>
                                </span>
                            </> :
                            <>
                                <h4>{document.name}</h4>
                                <Tippy content="Edit">
                                    <span onClick={(e) => {e.stopPropagation(); handleToggleEditMode(index, document)}}>
                                        <EditIcon />
                                    </span>
                                </Tippy>
                            </>
                        }
                    </div>
                    {editingCardIndex === index ?
                        <textarea
                            value={tempEditingDescription || document.description}
                            onChange={e => setTempEditingDescription(e.target.value)}
                            onBlur={() => handleSaveEdits(index)}
                            onKeyDown={e => {
                                if (e.key === 'Enter') handleSaveEdits(index);
                            }}
                            onClick={e => e.stopPropagation()}
                        /> :
                        <p>{document.description}</p>
                    }
                </div>
            ))}
        </div>
    );
};

export default Documents;





