// ExistingJobs.js
import React, { useEffect, useState } from 'react';
import api from './api'; 
import JobOverviewCard from './JobOverviewCard';
import { Link } from 'react-router-dom';
import './Projects.css';  
import './JobOverviewCard.css';
import { Modal, Button, Form } from 'react-bootstrap';
import { useContext } from 'react';
import BrainstormContext from './BrainstormContext';
import { useNavigate } from 'react-router-dom';
import { FaFolder } from 'react-icons/fa';
import './ExistingJobs.css';
import { Card } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';



const ExistingJobs = () => {
    const [jobs, setJobs] = useState([]);
    const [page, setPage] = useState(1);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [hasTriedToProceed, setHasTriedToProceed] = useState(false);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        IndustryTitle: '',
        IndustryDescription: '',
        CompanyType: '',
        JobTitle: '',
        JobDescription: ''
    });


    const {
        setSelectedJob, industryOptions, setSelectedIndustry,
        selectedIndustry,
    } = useContext(BrainstormContext);


    const renderModalContent = () => {
        return renderIndustrySelection();
    }

    const renderIndustrySelection = () => {
        if (loading) {
            return <div className="spinner-border"></div>;
        }
        return (
            <div className="tool-container centered-container">
                <Form.Group controlId="IndustryTitle" className="tool-card">
                    <Form.Label>Industry Title</Form.Label>
                    <Form.Control as="select" name="IndustryTitle" value={selectedIndustry || ''} onChange={(e) => {
                        handleInputChange(e);
                        setSelectedIndustry(e.target.value);
                    }}>
                        {industryOptions.map((option, index) => (
                            <option key={index} value={option}>{option}</option>
                        ))}
                    </Form.Control>
                </Form.Group>
            </div>
        );
    };
    


    useEffect(() => {
        console.log("Updated formData:", formData);
    }, [formData]); 

    
    useEffect(() => {
        if (!showModal) {
            setFormData({
                IndustryTitle: '',
                IndustryDescription: '',
                JobTitle: '',
                JobDescription: ''
            });
        }
    }, [showModal]);
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async () => {
        console.log("Submitting formData:", formData); 
        try {
            const response = await api.post('/api/create_new_job', formData, { withCredentials: true });
            
            // Set the newly created job in context
            setSelectedJob(response.data.job);   // <-- Adjusted this line
            console.log("Job set in context:", response.data.job);  // <-- Adjusted this line
            console.log("JobTitle from response data:", response.data.job.JobTitle); // <-- Adjusted this line
    
            // Navigate to the brainstorm_project route
            navigate("/brainstorm_project");
        } catch (error) {
            console.error('Failed to create new job:', error);
        }
    };
    

    const fetchJobs = async (page) => {
        try {
            const response = await api.get(`/api/jobs?page=${page}&per_page=10`, { withCredentials: true });
            setJobs(oldJobs => [...oldJobs, ...response.data]);
        } catch (error) {
            setError('Failed to fetch jobs. Please try again later.');
        }
    };

    useEffect(() => {
        fetchJobs(page);
    }, [page]);

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="jobs-container">
            <Card className="job-overview-card" onClick={() => { // Removed 'create-job-card' class
                setShowModal(true);
            }}>
                <Card.Body className="create-job-card-body" style={{ flex: 1 }}>
                    <div className="job-overview-card-header">
                        <div className="header-icon-group">
                            <FaPlus className="folder-icon"/>
                        </div>
                        <Card.Title>Create New Job</Card.Title>
                    </div>
                </Card.Body>
            </Card>
    
            {jobs.map((job) => (
                <div className="job-card-wrapper" key={job.JobID} 
                onClick={() => {
                    setSelectedJob(job);
                    console.log("Job set in context:", job);
                }}>
                    <Link to={{
                        pathname: "/brainstorm_project",
                        state: { job: job }
                    }} className="job-link">
                        <JobOverviewCard job={job} />
                    </Link>
                </div>
            ))}

    
            <button onClick={() => setPage(oldPage => oldPage + 1)} className="load-more-button">
                Load More Jobs
            </button>


            <Modal 
                show={showModal} 
                onHide={() => setShowModal(false)} 
                dialogAs={props => <Modal.Dialog {...props} style={{ width: '70vw', maxWidth: '100%', height: '90vh', maxHeight: '100%' }} />}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Create New Job</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {renderModalContent()}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={handleSubmit} disabled={!selectedIndustry}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ExistingJobs;