import React, { useState } from 'react';
import api from './api';
import './Account.css';
import Tippy from '@tippyjs/react';

const Account = () => {
    const [currentEmail, setCurrentEmail] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [ideName, setIdeName] = useState('-'); // Default to VSCode
    const [operatingSystem, setOperatingSystem] = useState('-'); // Default to macOS
    const [snackbar, setSnackbar] = useState({ message: '', type: '' });

    // Method to display the snackbar and auto-hide it
    const showSnackbar = (message, type) => {
        setSnackbar({ message, type });
        setTimeout(() => setSnackbar({ message: '', type: '' }), 3000); // hide after 3 seconds
    };



    const handleAccountUpdate = async (e) => {
        e.preventDefault();
        try {
            const response = await api.put('/api/account', {
                currentEmail,
                currentPassword,
                newEmail: newEmail === '' ? currentEmail : newEmail,
                newPassword
            }, { withCredentials: true });
    
            if (response.data.success) {
                showSnackbar("Your account information has been updated successfully!", "success");
            } else {
                showSnackbar(response.data.message || "Failed to update account information. Please try again.", "error");
            }
        } catch (error) {
            showSnackbar("An error occurred. Please try again.", "error");
        }
    };
    
    const handleProjectSettingsUpdate = async (e) => {
        e.preventDefault();
        try {
            const osResponse = await api.post('/api/save_computer', { operatingSystem, ideName });
    
            // Assuming both API calls should be successful for the settings to be considered updated
            if (osResponse.data.success) {
                showSnackbar("Your project settings have been updated successfully!", "success");
            } else {
                showSnackbar("Failed to update project settings. Please try again.", "error");
            }
        } catch (error) {
            showSnackbar("An error occurred. Please try again.", "error");
        }
    };
    
    

    return (
        <div className="account-container">

        
        <fieldset className="section">
            <legend className="section-title">Personalize CodeBuddy:</legend>
            <form onSubmit={handleProjectSettingsUpdate} className="account-form">
                <label htmlFor="operatingSystem" className="account-label">Update your Computer Type</label>
                <select id="operatingSystem" className="form-control account-input" value={operatingSystem} onChange={(e) => setOperatingSystem(e.target.value)}>
                    <option value="-"> — <span></span></option>
                    <option value="macOS">macOS <span></span></option>
                    <option value="Windows">Windows <span></span></option>
                </select>

                <label htmlFor="ideName" className="account-label">
                    <Tippy content="IDE stands for Integrated Development Environment. Opening your IDE for coding is like opening a Word document for writing, or opening a PowerPoint to make a slide deck.">
                        <span>Update Your IDE</span>
                    </Tippy>
                </label>
                <select id="ideName" className="form-control account-input" value={ideName} onChange={(e) => setIdeName(e.target.value)}>
                    <option value="-"> — <span></span></option>
                    <option value="VSCode">VSCode <span></span></option>
                    <option value="PyCharm">PyCharm <span></span></option>
                    <option value="Spyder">Spyder <span></span></option>
                </select>

                <button type="submit" className="btn btn-primary account-button">
                    Save Preferences
                </button>
            </form>
        </fieldset>
            {snackbar.message && <div className={`snackbar ${snackbar.type}`}>{snackbar.message}</div>}
        </div>
    );
};

export default Account;