// api.js
// http://localhost:5000
// https://www.codebuddyai.com


import axios from 'axios';
import axiosRetry from 'axios-retry';

const api = axios.create({
  baseURL: 'https://www.codebuddyai.com',
  withCredentials: true,
});

axiosRetry(api, {
  retries: 3, // number of retries
  retryDelay: (retryCount) => {
    return retryCount * 2000; // time interval between retries
  },
  retryCondition: (error) => {
    // Do not retry if the request was to /api/run_codebuild
    if (error.config.url === '/api/run_codebuild') {
      return false;
    }
    
    // if retry condition is not specified, by default idempotent requests are retried
    return error.response.status >= 500;
  },
});


export default api;


