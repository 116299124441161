// JobOverviewCard.js

import React from 'react';
import { Card } from 'react-bootstrap';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { FaUserPlus, FaShoppingCart, FaUserTie } from 'react-icons/fa';
import './JobOverviewCard.css';

const JobOverviewCard = ({ job }) => {
    const companyDescription = `${job.IndustryDescription}`;
    const jobDescription = `${job.JobDescription}`;

    const getIndustryIcon = () => {
        switch (job.IndustryTitle) {
            case 'Recruiting':
                return <FaUserPlus className="folder-icon"/>;
            case 'E-Commerce':
                return <FaShoppingCart className="folder-icon"/>;
            case 'Consulting':
                return <FaUserTie className="folder-icon"/>;
            default:
                return null; // Or return a default icon
        }
    };

    return (
        <Card className="job-overview-card">
            <Card.Body style={{ flex: 1 }}> {/* Added inline style */}
                <div className="job-overview-card-header">
                    <div className="header-icon-group">
                        {getIndustryIcon()}
                    </div>
                    <Card.Title>{job.IndustryTitle}</Card.Title>
                </div>
                <Tippy content={jobDescription}>
                    <Card.Subtitle className="mb-1 text-muted">{job.JobTitle}</Card.Subtitle>
                </Tippy>
                <Tippy content={companyDescription}>
                    <Card.Subtitle className="mb-2 text-muted">{job.CompanyType}</Card.Subtitle>
                </Tippy>
            </Card.Body>
        </Card>
    );
    
};

export default JobOverviewCard;
