// ProjectSummary.js

import React, { useContext, useState } from 'react';
import './ProjectSummary.css';
import { FaChevronUp } from 'react-icons/fa';
import BrainstormProgressBar from './BrainstormProgressBar';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AutorenewIcon from '@mui/icons-material/Autorenew';  // <-- Import the AutorenewIcon
import Tippy from '@tippyjs/react';
import './BrainstormProgressBar.css';
import BrainstormSideBar from './BrainstormSideBar';
import { ProjectContext } from './ProjectContext'; // Import ProjectContext
import ProjectMetrics from './ProjectMetrics';
import api from './api';
import SaveIcon from '@mui/icons-material/Save';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import BrainstormContext from './BrainstormContext';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ErrorIcon from '@mui/icons-material/Error';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';




const ProjectSummary = ({openSnackbar, setOpenSnackbar, snackbarMessage, setSnackbarMessage}) => {
    const { project, setProject } = useContext(ProjectContext); // Use useContext to access project and projectMetrics
    const [isEditing, setIsEditing] = useState(false);
    const [editedName, setEditedName] = useState(project?.ProjectIdea);
    const { currentStep } = useContext(BrainstormContext);
    const [openModal, setOpenModal] = useState(false);
    const [isProjectStepsModalOpen, setIsProjectStepsModalOpen] = useState(false);
    const [projectStepsModalContent, setProjectStepsModalContent] = useState('');

  //  console.log ('Project Summary Project:', project);
   // console.log ('Project Summary Project Metrics:', projectMetrics);
 //   console.log ('Project Summary Current Step:', currentStep);

    const getProjectIcon = () => {
        switch(project?.ProjectStatus) {
            case "Deployed":
                return <CheckCircleOutlineIcon className="project-icon" style={{ color: 'green' }} />;
            case "In Progress":
                return <HourglassEmptyIcon className="project-icon spin-on-hover" style={{ color: '#007BFF' }} />;
            case "Deployment On-Going":
                return <AutorenewIcon className="project-icon rotate-icon" style={{ color: 'orange' }} />;
            case "Deployment Error":
                return <ErrorIcon className="project-icon" style={{ color: 'red' }} />;
            case "Ready to Deploy":
                return <PlayCircleFilledIcon className="project-icon" style={{ color: 'orange' }} />;
            default:
                return null;
        }
    };
    
    
    
    
    
    const getProjectTooltip = () => {
        switch(project?.ProjectStatus) {
            case "Deployed":
                return "Deployed";
            case "In Progress":
                return "In Progress";
            case "Deployment On-Going":
                return "Deployment On-Going";
            case "Deployment Error":
                return "Deployment Error";
            case "Ready to Deploy":
                return "Ready to Deploy";
            default:
                return "";
        }
    };
    
    


    const handleProjectStepsModalOpen = () => {
        // Set the content for the modal (you can adjust this as needed)
        setProjectStepsModalContent(project?.ProjectStatus + ': you have ' + (8 - currentStep) + ' steps remaining until your project is ready to deploy.');
        setIsProjectStepsModalOpen(true);
    };
    

    const handleModalOpen = () => {
        handleProjectStepsModalOpen();
    };
    

    const handleModalClose = () => {
        setOpenModal(false);
    };



    const handleEditStart = () => {
        setIsEditing(true);
    };
    
    const handleEditChange = (e) => {
        setEditedName(e.target.value);
    };
    
    const handleEditEnd = () => {
        setProject(prev => ({ ...prev, ProjectIdea: editedName }));
        setIsEditing(false);
    };

    const saveProject = async () => {
        try {
            const response = await api.post('/api/save_project', {
                projectID: project?.ProjectID,
                Industry: project?.Industry,
                ProjectName: project?.ProjectIdea,
                ProjectCompany: project?.ProjectCompany,
                ProjectCompanyDescription: project?.ProjectCompanyDescription,
                ProjectJob: project?.ProjectJob,
                ProjectJobDescription: project?.ProjectJobDescription,
                ProjectIdea: project?.ProjectIdea,
                ProjectIdeaDescription: project?.ProjectIdeaDescription,
                ProjectDocuments: project?.ProjectDocuments,
                ProjectDocumentDescriptions: project?.ProjectDocumentDescriptions,
                ProjectAI: project?.ProjectAI,
                ProjectAIDescription: project?.ProjectAIDescription,
                ProjectSlackChannel: project?.ProjectSlackChannel,
                ProjectSlackChannelDescription: project?.ProjectSlackChannelDescription,
                currentStep: currentStep,
            });

            if (response.data.success) {
                setSnackbarMessage('Project saved successfully!');
                setOpenSnackbar(true);
            } else {
                setSnackbarMessage(response.data.message);
                setOpenSnackbar(true);
            }
            
        } catch (error) {
            // Handle unexpected errors
            console.error("Error saving the project:", error);
        }
    };
    
    
    


    return (
        <section className="project-summary-container">
    
            <div className="project-header">
    
                <Tippy content={isEditing ? "Edit Project Name" : "Click to Edit"}>
                    <div className="project-name" onClick={handleEditStart}>
                        {isEditing ? (
                            <input
                                className="project-name-input"
                                value={editedName}
                                onChange={handleEditChange}
                                onBlur={handleEditEnd}
                                onKeyDown={(e) => { if (e.key === 'Enter') handleEditEnd(); }}
                                autoFocus
                            />
                        ) : (
                            <h2>{project?.ProjectIdea}</h2>
                        )}
                    </div>

                </Tippy>

                    <div className="project-icon-container" onClick={handleModalOpen}>
                        <Tippy content="Save Project">
                            <SaveIcon className="save-icon" onClick={saveProject} />
                        </Tippy>
                        <Tippy content={getProjectTooltip()}>
                            {getProjectIcon()}
                        </Tippy>
                    </div>
        
                    <div className="project-progress">
                        <BrainstormProgressBar />
                    </div>


            </div>

                <BrainstormSideBar />
            {isProjectStepsModalOpen && (
                <div className="brainstorm-modal">
                    <div className="brainstorm-modal-content">
                        <Tippy content="Close">
                            <span className="brainstorm-close-button" onClick={() => setIsProjectStepsModalOpen(false)}>×</span>
                        </Tippy>
                        <div className="steps-modal-content-text">
                            <p>{projectStepsModalContent}</p>
                        </div>
                    </div>
                </div>

            )}


    
        </section>
    );
    
    
};

export default ProjectSummary;
