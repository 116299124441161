// DeploymentSummary.js

import React, { useContext } from 'react';
import { ProjectContext } from './ProjectContext';
import Tooltip from '@mui/material/Tooltip';
import { HourglassEmpty, Build, CheckCircleOutline } from '@mui/icons-material';
import './DeploymentSummary.css';

const DeploymentSummary = () => {
    const { deployment } = useContext(ProjectContext);

    if (!deployment) return null;

    const getStatusIcon = (status) => {
        switch (status) {
            case 'Not Started':
                return <Tooltip title="URL Not Live"><HourglassEmpty /></Tooltip>;
            case 'In Progress':
                return <Tooltip title="URL In Progress"><Build /></Tooltip>;
            case 'SUCCEEDED':
                return <Tooltip title="URL Succeeded"><CheckCircleOutline /></Tooltip>;
            default:
                return null;
        }
    };

    return (
        <div className="deployment-summary">
            <h3>Deployment Summary</h3>
            <p><span className="status-label">Deployment Status:</span> {deployment.DeploymentStatus}</p>
            <p><span className="status-label">Parameter Store Status:</span> {deployment.ParameterStoreStatus}</p>
            <p><span className="status-label">S3 Status:</span> {deployment.S3Status}</p>
            <p><span className="status-label">CodeBuild Status:</span> {deployment.CodeBuildStatus}</p>
            <p><span className="status-label">ECS Status:</span> {deployment.EcsStatus}</p>
            <p>
                <span className="status-label">URL:</span> 
                <a href={deployment.DeploymentURL} target="_blank" rel="noopener noreferrer">
                    {getStatusIcon(deployment.DeploymentStatus)}
                    {deployment.DeploymentURL}
                </a>
            </p>
        </div>
    );    
};

export default DeploymentSummary;
