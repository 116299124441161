import React, { useState } from 'react';
import api from './api';  // Import your axios instance
import { useNavigate } from 'react-router-dom';
import './Signup.css';

const Signup = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await api.post('/api/signup', { firstName, lastName, password, email }, { withCredentials: true });  // Use your axios instance
            if (response.data.success) {
                navigate("/login");
            } else {
                setError('Signup failed');
            }
        } catch (error) {
            setError('An error occurred');
        }
    };
    
    return (
        <div className="signup-container">
            <h2 className="signup-title">Sign Up for CodeBuddy</h2>
            <form onSubmit={handleSubmit} className="signup-form">
                <label htmlFor="firstName" className="signup-label">First Name</label>
                <input id="firstName" type="text" className="form-control signup-input" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="Jayson" />
                <label htmlFor="lastName" className="signup-label">Last Name</label>
                <input id="lastName" type="text" className="form-control signup-input" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="Tatum" />
                <label htmlFor="email" className="signup-label">Email</label>
                <input id="email" type="email" className="form-control signup-input" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="jaysontatum@celtics.com" />
                <label htmlFor="password" className="signup-label">Password</label>
                <input id="password" type="password" className="form-control signup-input" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="JaysonTatumRules!" />
                <button type="submit" className="btn btn-primary signup-button">Sign Up</button>
                {error && <p className="signup-error">{error}</p>}
            </form>
            <p className="login-link">Already have an account? <a href="/login">Log in</a></p>
        </div>
    );
};


export default Signup;
