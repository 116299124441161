// Tools.js
import React, { useState, useEffect, useRef, useContext } from 'react';
import './Industry.css';
import api from './api';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; 
import CircularProgress from '@mui/material/CircularProgress';
import BrainstormContext from './BrainstormContext';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { ProjectContext } from './ProjectContext'; // <-- Add this import


const Tools = () => {
    const [tools, setTools] = useState([]);
    const [loadingTools, setLoadingTools] = useState(true);
    const [activeCardIndex, setActiveCardIndex] = useState(null);
    const previousJobRef = useRef();
    const [editingCardIndex, setEditingCardIndex] = useState(null);
    const [tempEditingName, setTempEditingName] = useState('');
    const [tempEditingDescription, setTempEditingDescription] = useState('');
    const { 
        continueButtonRef,
        setSelectedData,
    } = useContext(BrainstormContext);
    const { project, setProject, projectMetrics } = useContext(ProjectContext); // <-- Use the project object from ProjectContext
    const previousProjectRef = useRef(); // <-- Add this useRef


    
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (continueButtonRef.current && continueButtonRef.current.contains(event.target)) {
                return;
            }
            if (!event.target.closest('.industry-card')) {
                setActiveCardIndex(null);
                setProject(prev => ({ ...prev, ProjectSlackChannel: null, ProjectSlackChannelDescription: null })); // <-- Set ProjectSlackChannel and ProjectSlackChannelDescription to null
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, []);
    
    useEffect(() => {
        if (previousProjectRef.current !== project.Industry) {
            const fetchTools = async () => {
                try {

                    const metricsData = projectMetrics.map(metric => ({
                        name: metric.name,
                        description: metric.description,
                        formula: metric.formula
                    }));


                    const response = await api.post('/api/generate_tools', {
                        projectID: project.ProjectID,
                        industryName: project.Industry,
                        industryDescription: project.ProjectCompanyDescription,
                        companyType: project.ProjectCompany,
                        jobName: project.ProjectJob,
                        jobDescription: project.ProjectJobDescription,
                        ideaName: project.ProjectIdea,
                        ideaDescrption: project.ProjectIdeaDescription,
                        documentName: project.ProjectDocuments,
                        documentDescription: project.ProjectDocumentDescriptions,
                        projectAI: project.ProjectAI,
                        projectAIDescription: project.ProjectAIDescription,
                        metrics: metricsData // Add this line to send the metrics

                    });
                    setTools(response.data.tools);
                } catch (error) {
                    console.error('Error fetching tools:', error);
                } finally {
                    setLoadingTools(false);
                }
            };
            fetchTools();
        }
        previousProjectRef.current = project.Industry;
    }, [project]); // <-- Update the dependency array
    

    const handleCardClick = (index) => {
        if (activeCardIndex === index) {
            setActiveCardIndex(null);
            setSelectedData(null);
        } else {
            setActiveCardIndex(index);
            setSelectedData(true);
            setProject(prev => ({ 
                ...prev, 
                ProjectSlackChannel: tools[index].name,  // <-- Set ProjectSlackChannel
                ProjectSlackChannelDescription: tools[index].description,  // <-- Set ProjectSlackChannelDescription
            }));
        }
    };

    const handleToggleEditMode = (index, tool) => {
        if (editingCardIndex === index) {
            setEditingCardIndex(null);
            setTempEditingName('');
            setTempEditingDescription('');
        } else {
            setEditingCardIndex(index);
            setTempEditingName(tool.name);
            setTempEditingDescription(tool.description);
        }
    };

    const handleSaveEdits = (index) => {
        tools[index].name = tempEditingName || tools[index].name;
        tools[index].description = tempEditingDescription || tools[index].description;
        setTools([...tools]);
        setEditingCardIndex(null);
        setTempEditingName('');
        setTempEditingDescription('');
    };

    if (loadingTools) {
        return (
            <div className="industry-loading">
                <CircularProgress color="primary" style={{ margin: '20px', alignContent: 'center' }} />
            </div>
        );
    }

    console.log('Project Context in Tools.js:', project);

    return (
        <div className="industry-container">
            {tools.map((tool, index) => (
                <div 
                    key={index} 
                    className={`industry-card ${activeCardIndex === index ? 'active' : ''} ${editingCardIndex === index ? 'editing' : ''}`} 
                    onClick={() => handleCardClick(index)}
                >
                    <div className="card-header">
                        {editingCardIndex === index ? 
                            <>
                                <input 
                                    value={tempEditingName || tool.name} 
                                    onChange={e => setTempEditingName(e.target.value)}
                                    onBlur={() => handleSaveEdits(index)}
                                    onKeyDown={e => {
                                        if (e.key === 'Enter') handleSaveEdits(index);
                                    }}
                                    onClick={e => e.stopPropagation()}
                                />
                                <span className="edit-icons">
                                    <Tippy content="Save">
                                        <SaveIcon onClick={(e) => { e.stopPropagation(); handleSaveEdits(index); }} />
                                    </Tippy>
                                </span>
                            </> :
                            <>
                                <h4>{tool.name}</h4>
                                <Tippy content="Edit">
                                    <span onClick={(e) => {e.stopPropagation(); handleToggleEditMode(index, tool)}}>
                                        <EditIcon />
                                    </span>
                                </Tippy>
                            </>
                        }
                    </div>
                    {editingCardIndex === index ? 
                        <textarea 
                            value={tempEditingDescription || tool.description} 
                            onChange={e => setTempEditingDescription(e.target.value)}
                            onBlur={() => handleSaveEdits(index)}
                            onKeyDown={e => {
                                if (e.key === 'Enter') handleSaveEdits(index);
                            }}
                            onClick={e => e.stopPropagation()}
                        /> :
                        <p>{tool.description}</p>
                    }
                </div>
            ))}
        </div>
    );
};

export default Tools;
