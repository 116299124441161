import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './ProjectCard.css';

const NewProjectCard = () => {
    const navigate = useNavigate();

    const handleStart = () => {
        navigate('/jobs');
    };

    return (
        <Card className="new-project-card">
            <Card.Body>
                <Card.Title>Create New Project</Card.Title>
                <Button variant="primary" onClick={handleStart}>Start</Button>
            </Card.Body>
        </Card>
    );
};

export default NewProjectCard;
