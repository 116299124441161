// ProjectOverviewCard.js

import React, { useState, useEffect } from 'react';
import { Card, ProgressBar } from 'react-bootstrap';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import api from './api';
import { FaFolder, FaCode } from 'react-icons/fa';
import './ProjectOverviewCard.css';

const ProjectOverviewCard = ({ project }) => {
  const progress = (project.ProjectStepsCompleted / project.ProjectSteps) * 100;
  
  let variant = "success";
  if (progress < 50) variant = "danger";
  else if (progress < 80) variant = "warning";

  const tooltipContent = `${project.ProjectStepsCompleted} out of ${project.ProjectSteps} steps completed.`;

  return (
    <Card className="project-overview-card">
      <Card.Body>
        <div className="project-overview-card-header">
          <div className="header-icon-group">
            <FaFolder className="folder-icon"/>
          </div>
          <Tippy content={project.ProjectStatus}>
            <Card.Title>{project.ProjectName}</Card.Title>
          </Tippy>
        </div>
        <div className="progress-section">
          <p className="progress-title">Progress:</p>
          <Tippy content={tooltipContent}>
            <ProgressBar className="file-progress-bar" variant={variant} now={progress} label={`${Math.round(progress) || 0}%`} />
          </Tippy>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ProjectOverviewCard;
