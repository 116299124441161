// Projects.js
import React, { useEffect, useState } from 'react';
import api from './api'; 
import ProjectOverviewCard from './ProjectOverviewCard';
import { Link } from 'react-router-dom';
import './Projects.css';
import './ProjectOverviewCard.css';
import { Modal, Button, Form } from 'react-bootstrap';
import { useContext } from 'react';
import BrainstormContext from './BrainstormContext';
import { FaPlus } from 'react-icons/fa';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { ProjectContext } from './ProjectContext';
import CircularProgress from '@mui/material/CircularProgress';



const Projects = () => {
    const [projects, setProjects] = useState([]);
    const [page, setPage] = useState(1);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        IndustryTitle: '',
        IndustryDescription: '',
        CompanyType: '',
        JobTitle: '',
        JobDescription: ''
    });
    const {
        setSelectedJob, industryOptions, setSelectedIndustry,
        selectedIndustry, setCurrentStep, resetCurrentStep, resetSelectedIndustry, resetIndustryOptions
    } = useContext(BrainstormContext);

    const {setProject, resetProject} = useContext(ProjectContext);

    useEffect(() => {
        resetIndustryOptions();
    }, []);

    useEffect (() => {
        resetSelectedIndustry();
    }, []);
    

    useEffect(() => {
        resetCurrentStep();
    }, []);


    useEffect(() => {
        resetProject();
    }, []);

    const fetchProjects = async (page) => {
        try {
            const response = await api.get(`/api/projects?page=${page}&per_page=10`, { withCredentials: true });
            setProjects(oldProjects => [...oldProjects, ...response.data]);
        } catch (error) {
            setError('Failed to fetch projects. Please try again later.');
        }
    };

    useEffect(() => {
        fetchProjects(page);
    }, [page]);

    if (error) {
        return <div>Error: {error}</div>;
    }

    const renderModalContent = () => {
        return renderIndustrySelection();
    }
    
    const renderIndustrySelection = () => {
        if (loading) {
            return <div className="spinner-border"></div>;
        }
        return (
            <div className="industry-form-container">
                <Autocomplete
                    className="mui-autocomplete"
                    name="IndustryTitle"
                    value={selectedIndustry || ''}
                    onChange={(event, newValue) => {
                        handleInputChange({ target: { name: 'IndustryTitle', value: newValue } });
                        setSelectedIndustry(newValue);
                    }}
                    freeSolo
                    options={industryOptions}
                    renderInput={(params) => (
                        <TextField {...params} label="What industry do you work in?" variant="outlined" />
                    )}
                />
            </div>
        );
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    
    
    const handleSubmit = async () => {
        setLoading(true);
        console.log("Submitting formData:", formData); 
        try {
            const jobResponse = await api.post('/api/create_new_job', formData, { withCredentials: true });
    
            const projectData = {
                JobID: jobResponse.data.jobID,
                IndustryTitle: formData.IndustryTitle
            };

       //     const generateProjectName = await api.post('/api/generate_project_name', projectData, { withCredentials: true });
      //      projectData.ProjectName = generateProjectName.data.project_name;



            const projectResponse = await api.post('/api/create_new_project', projectData, { withCredentials: true });
            setProject(projectResponse.data.project); // Set the current project in the ProjectContext

            // Create steps for the project
            const stepsData = {
                ProjectID: projectResponse.data.project.ProjectID
            };
            await api.post('/api/create_new_project_steps', stepsData, { withCredentials: true });
    
            // Navigate to the brainstorm_project route
            navigate(`/brainstorm_project/${projectResponse.data.project.ProjectID}`);
        } catch (error) {
            console.error('Failed to create new job or project:', error);
        } finally {
            setLoading(false); // Ensure we set loading back to false regardless of success or failure
        }
    };
    

    return (
        <div className="projects-container">
            <Card className="job-overview-card" onClick={() => {
                setShowModal(true);
            }}>
                <Card.Body className="create-job-card-body" style={{ flex: 1 }}>
                    <div className="job-overview-card-header">
                        <div className="header-icon-group">
                            <FaPlus className="folder-icon"/>
                        </div>
                        <Card.Title>Create New Project</Card.Title>
                    </div>
                </Card.Body>
            </Card>
            
            {projects.map((project) => (
                <div className="project-card-wrapper" key={project.ProjectID}>
                    {project.ProjectStepsCompleted === 8 ? (
                        <Link 
                            to={`/${project.ProjectName}/${project.ProjectID}`} 
                            className="project-link"
                            onClick={() => setCurrentStep(8)}
                        >
                            <ProjectOverviewCard project={project} />
                        </Link>
                    ) : (
                        <Link 
                            to={`/brainstorm_project/${project.ProjectID}`} 
                            className="project-link"
                            onClick={() => {
                                setCurrentStep(project.ProjectStepsCompleted);
                                setProject(project); // Set the current project in the ProjectContext
                            }}
                        >
                            <ProjectOverviewCard project={project} />
                        </Link>
                    )}
                </div>
            ))}

            
            <button onClick={() => setPage(oldPage => oldPage + 1)} className="load-more-button">
                Load More Projects
            </button>
    
            <Modal 
                show={showModal} 
                onHide={() => setShowModal(false)} 
                dialogAs={props => <Modal.Dialog {...props} style={{ width: '70vw', maxWidth: '100%', height: '90vh', maxHeight: '100%' }} />}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Please choose an industry for this project:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? (
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                            <CircularProgress color="primary" />
                        </div>
                    ) : (
                        renderModalContent()
                    )}
                </Modal.Body>

    
                <Modal.Footer>
                    {!loading && (
                        <Button variant="primary" onClick={handleSubmit} disabled={!selectedIndustry}>
                            Submit
                        </Button>
                    )}
                </Modal.Footer>


            </Modal>
        </div>
    );    
};

export default Projects;
