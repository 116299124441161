// DeploymentCard.js

import React, { useState, useEffect } from 'react';
import './DeploymentCard.css';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import LinearProgress from '@mui/material/LinearProgress';

const DeploymentCard = ({ step }) => {
    const [timer, setTimer] = useState(0);
    const timerRef = useState(null);

    const progressPercentage = (step.status === 'In Progress' && step.estimatedTime)
        ? (timer / step.estimatedTime * 100)
        : 0;

    useEffect(() => {
        if (step.status === 'In Progress') {
            timerRef.current = setInterval(() => {
                setTimer(prevTime => prevTime + 1);
            }, 1000);
        } else if (timerRef.current) {
            clearInterval(timerRef.current);
        }

        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }, [step.status]);

    const formatTime = (seconds) => {
        return `${seconds} second${seconds !== 1 ? 's' : ''}`;
    };
    

    const iconMap = {
        'Completed': <CheckCircleIcon className="status-icon completed-icon" />,
        'In Progress': <CircularProgress className="status-icon in-progress-icon" />,
        'Not Started': <ErrorIcon className="status-icon not-started-icon" />
    };

    return (
        <div className={`deployment-card ${step.status.toLowerCase()}`}>
            <div className="deployment-icon">
                {iconMap[step.status]}
            </div>
            <div className="deployment-content">
                <div className="deployment-title">{step.title}</div>
                <div className="deployment-message">{step.message}</div>
                {step.url && <p>URL: <a href={step.url} target="_blank" rel="noopener noreferrer">{step.url}</a></p>}
            </div>
            <div className="deployment-timers">
                {(step.status === 'In Progress' || step.status === 'Completed') && <span>Actual: {formatTime(timer)}</span>}
                <span>Estimated: {step.estimatedTime} seconds</span>
                {step.status === 'In Progress' && <LinearProgress value={progressPercentage} />}
            </div>
        </div>
    );
};

export default DeploymentCard;
