//App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Login from './Login';
import Signup from './Signup';
import Projects from './Projects';
import NavBar from './NavBar'; 
import Account from './Account'; 
import Choose from './Choose';
import InitializeProject from './InitializeProject';
import InitializeProjectCard from './InitializeProjectCard';
import ChatBot from './ChatBot';
import ViewProject from './ViewProject'; 
import BrainstormProject from './BrainstormProject';
import BrainstormProvider from './BrainstormProvider';
import { ProjectProvider } from './ProjectContext';
import ExistingJobs from './ExistingJobs';


function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);  // Initialize to false

    return (
        <Router>
            <NavBar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
            <BrainstormProvider>
                <Routes>
                    <Route path="/" element={<Home isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
                    <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/account" element={isLoggedIn ? <Account setIsLoggedIn={setIsLoggedIn} /> : <Login setIsLoggedIn={setIsLoggedIn} />} />
                    <Route path="/choose" element={isLoggedIn ? <Choose setIsLoggedIn={setIsLoggedIn} /> : <Login setIsLoggedIn={setIsLoggedIn} />} />
                    <Route path="/initialize_project" element={isLoggedIn ? <InitializeProject setIsLoggedIn={setIsLoggedIn} /> : <Login setIsLoggedIn={setIsLoggedIn} />} />
                    <Route path="/jobs" element={isLoggedIn ? <ExistingJobs /> : <Login setIsLoggedIn={setIsLoggedIn} />} />
                    <Route path="/brainstorm_project" element={isLoggedIn ? <BrainstormProject /> : <Login setIsLoggedIn={setIsLoggedIn} />} />
                    <Route path="/initialize-project-card" element={isLoggedIn ? <InitializeProjectCard setIsLoggedIn={setIsLoggedIn} /> : <Login setIsLoggedIn={setIsLoggedIn} />} />
                    <Route path="/chatbot" element={isLoggedIn ? <ChatBot setIsLoggedIn={setIsLoggedIn} /> : <Login setIsLoggedIn={setIsLoggedIn} />} />
                    <Route path="/projects" element={isLoggedIn ? (
                        <ProjectProvider>
                            <Projects />
                        </ProjectProvider>
                    ) : <Login setIsLoggedIn={setIsLoggedIn} />} />
                    <Route path="/brainstorm_project/:projectID" element={isLoggedIn ? (
                    <ProjectProvider>
                        <BrainstormProject />
                    </ProjectProvider>
                ) : <Login setIsLoggedIn={setIsLoggedIn} />} />
                    <Route path="/:projectName/:projectID" element={isLoggedIn ? (
                        <ProjectProvider>
                            <ViewProject />
                        </ProjectProvider>
                    ) : <Login setIsLoggedIn={setIsLoggedIn} />} />
                </Routes>
            </BrainstormProvider>
        </Router>
    );
}

export default App;

