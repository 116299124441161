// BrainstormProject.js
import React, { useState, useContext, useEffect } from 'react';
import './BrainstormProject.css';
import api from './api';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import BrainstormContext from './BrainstormContext';
import { CircularProgress } from '@mui/material';
import Task from './Task';
import LoadingDetails from './LoadingDetails';
import { useNavigate } from 'react-router-dom';
import BrainstormSideBar from './BrainstormSideBar';
import BrainstormProgressBar from './BrainstormProgressBar';
import Industry from './Industry';
import Works from './Works';
import Idea from './Idea';
import UploadCSV from './UploadCSV';
import Documents from './Documents';
import Tools from './Tools';
import { ProjectContext } from './ProjectContext';
import ProjectSummary from './ProjectSummary';
import { ProjectProvider } from './ProjectContext';
import { create } from '@mui/material/styles/createTransitions';


const BrainstormProject = () => {
    console.log("BrainstormProject component rendered");
    const [loading, setLoading] = useState(false);
    const [showLoadingDetails, setShowLoadingDetails] = useState(false);
    const [forceUpdate, setForceUpdate] = useState(0);
    const [projectReady, setProjectReady] = useState(false);
    const [stepStartTimes, setStepStartTimes] = useState({});
    const navigate = useNavigate();

    const { project, setProject, projectMetrics, setProjectMetrics, deployment, setDeployment } = useContext(ProjectContext);
    // Use setProject wherever needed to update the project context


   const {
      currentStep, setCurrentStep,
      selectedIndustry, setSelectedIndustry,
      openSnackbar, setOpenSnackbar,
      snackbarMessage, setSnackbarMessage,
      alertSeverity, setAlertSeverity,
      showContinueButton, setShowContinueButton,
      continueButtonRef,
      JobID, setJobID,
      selectedJob, setSelectedJob,
      selectedTool,
      selectedIdea,
      projectID, setProjectID,
      selectedDocument, setSelectedDocument,
      selectedTask, setSelectedTask,
      TemplateID, setTemplateID,
      setProjectName,
      setProjectSentence,
      projectName,
      projectSentence,
      fileInfo, setFileInfo,
      configInfo, setConfigInfo,
      selectedWork, setSelectedWork,
      metrics, setMetrics,
      selectedMetricIndices, setSelectedMetricIndices,
      ideas, setIdeas,
      setMetricsFactoryResponse,
      selectedData, setSelectedData,
      isStepLoaded, setIsStepLoaded
  } = useContext(BrainstormContext);

  const [isTitleVisible, setIsTitleVisible] = useState(false);


  useEffect(() => {
      setIsTitleVisible(false);
        setTimeout(() => {
            setIsTitleVisible(true);
        }, 100);
    }, [currentStep]);

    useEffect(() => {
        setStepStartTimes(prevTimes => ({
            ...prevTimes,
            [currentStep]: new Date().toISOString()
        }));
    }, [currentStep]);


    useEffect(() => {
        setIsStepLoaded(true);
    }, [currentStep]);


    const handleProjectReady = () => {
        navigate(`/${project.ProjectIdea}/${project.ProjectID}`);
    };

    const handleIndustryStep = async () => {
        if(!project || !project.ProjectCompany) {
            displaySnackbar("Please select a company before continuing", "warning");
            return;
        }
        try {
        //  await saveIndustry();
            displaySnackbar("Industry saved. Fetching jobs...", "info");

        } catch (error) {
            setLoading(false); // Stop showing the circular progress spinner in case of error
            console.error('Error:', error);
            displaySnackbar(error.message || "An error occurred", "error");
        }
    };

    const handleWorkStep = async () => {
        if (!project || !project.ProjectJob) {
            displaySnackbar("Please select a work before continuing", "warning");
            return;
        }


        try {
       //     await saveWorks();
            displaySnackbar("Work saved. Fetching jobs...", "info");

        } catch (error) {
            setLoading(false); // Stop showing the circular progress spinner in case of error
            console.error('Error:', error);
            displaySnackbar(error.message || "An error occurred", "error");
        }
    };


    const handleIdeaStep = async () => {
        if (!project || !project.ProjectIdea) {
            displaySnackbar("Please select an idea before continuing", "warning");
            return;
        }
        
        try {
            await saveStepDetails(currentStep);
            displaySnackbar("Idea saved. Initializing project...", "info");
            setLoading(true);  // Start showing the circular progress spinner
            await createFiles();
    

            console.log("Current projectID after creating project:", projectID);

    
        } catch (error) {
            setLoading(false); // Stop showing the circular progress spinner in case of error
            console.error('Error:', error);
            displaySnackbar(error.message || "An error occurred", "error");
        }
    };
    
    
    
    const saveIdea = async () => {
        setLoading(true);  // Start showing the circular progress spinner
    
        const response = await api.post('/api/save_idea', {
            ideaName: selectedIdea.name,
            ideaDescription: selectedIdea.description
        });
    
        if (!response.data.success) {
            setLoading(false); // Stop showing the circular progress spinner in case of error
            throw new Error('Failed to save idea');
        }
    };
    

    const getTemplates = async () => {
        displaySnackbar("Fetching template...", "info");
        const templateResponse = await api.post('/api/get_templates', {
            job_id: selectedJob.JobID
        });
        if (!templateResponse.data.template_name) {
            throw new Error('Failed to get recommended template');
        }
        setTemplateID(templateResponse.data.template_id);
        console.log("Template ID:", templateResponse.data.template_id);
        return templateResponse.data.template_id;
    };
    

    


    const createFiles = async () => {
        if (!project || !project.ProjectID) return;  // Exit if project or ProjectID is missing
        
        const projectID = project.ProjectID;
        
        console.log("Sending projectID to backend:", projectID);
        displaySnackbar("Generating files...", "info");
            
        const responseCreateFiles = await api.post('/api/create_files', {
            project_id: projectID
        });
            
        if (responseCreateFiles.data.message !== 'Files created successfully!') {
            throw new Error('Failed to create files');
        }
            
        // Update this part with a callback
        setFileInfo(responseCreateFiles.data.file_info);
        console.log("Response from /api/create_files:", responseCreateFiles.data);
        displaySnackbar("Files created successfully", "success");
    
        // Call createConfigs after files are created
        await createConfigs(project.projectID);
    };
    
    
    

    const createConfigs = async () => {
        if (!project || !project.ProjectID) return;  // Exit if project or ProjectID is missing
        
        const projectID = project.ProjectID;
        displaySnackbar("Setting up configurations...", "info");
        console.log("Creating configs for projectID:", projectID);
        
        const responseCreateConfigs = await api.post('/api/create_configs', {
            project_id: projectID
        });
    
        
        if (responseCreateConfigs.data.message !== 'Configs created successfully!') {
            throw new Error('Failed to create configs');
        }

        // Update this part with a callback
        setConfigInfo(responseCreateConfigs.data.config_info);
        console.log("Response from /api/create_configs:", responseCreateConfigs.data);

        displaySnackbar("Your project has been initialized successfully", "success");
        setLoading(false); // Stop showing the circular progress spinner
        setCurrentStep(currentStep + 1);
    };

    



    const saveSelectedMetrics = async () => {
        const selectedMetricsText = projectMetrics
            .filter((_, index) => selectedMetricIndices.includes(index))
            .map(metric => `${metric.name} ||| ${metric.description} ||| ${metric.formula}`)
            .join('\n');
        try {
            const response = await api.post('/api/save_metrics', {
                projectID: project.ProjectID, // Use project.ProjectID
                metrics: selectedMetricsText
            });
            if (!response.data.success) {
                throw new Error('Failed to save selected metrics');
            }
        } catch (error) {
            console.error('Error:', error);
            displaySnackbar(error.message || "An error occurred", "error");
        }
    };

    
    
    
    const saveTool = async () => {
        const response = await api.post('/api/save_tool', {
            toolName: selectedTool.name,
            toolDescription: selectedTool.description
        });
        if (!response.data.success) {
            throw new Error('Failed to save tool');
        }
    };


    const saveDocuments = async () => {
        // Assuming you have the project from context available as `project`
        const separators = ['&', ' and ', '/', '+'];
        let [document1Name, document2Name] = [project.ProjectDocuments, ''];  // Default values
    
        for (let sep of separators) {
            if (project.ProjectDocuments && project.ProjectDocuments.includes(sep)) {
                [document1Name, document2Name] = project.ProjectDocuments.split(sep).map(doc => doc.trim());
                break;
            }
        }
    
        const response = await api.post('/api/save_documents', {
            projectID: project.ProjectID,
            document1Name,
            document2Name,
            documentDescription: project.ProjectDocumentDescriptions
        });
    
        if (!response.data.success) {
            throw new Error('Failed to save document');
        }
    };
    

    const createMetricsFile = async () => {
        return api.post('/api/create_metrics_file', {
            projectID: project.ProjectID // Use project.ProjectID
        }).then(response => {
            if (!response.data.success) {
                console.error('Failed to generate metrics.py');
                throw new Error('Failed to generate metrics.py');  // Throwing an error to catch it later if needed
            } else {
                console.log("Metrics.py generated successfully!");
            }
        }).catch(error => {
            console.error('Error:', error);
            throw error;
        });
    };

    
    
    

    const createMetricsPy = async () => {
        return api.post('/api/code_metrics_py', {
            projectID: project.ProjectID // Use project.ProjectID
        }).then(response => {
            if (!response.data.success) {
                console.error('Failed to generate metrics.py');
                throw new Error('Failed to generate metrics.py');  // Throwing an error to catch it later if needed
            } else {
                console.log("Metrics.py generated successfully!");
            }
        }).catch(error => {
            console.error('Error:', error);
            throw error;
        });
    };
    
    const createMetricsFactoryPy = async () => {
        return api.post('/api/code_metrics_factory_py', {
            projectID: project.ProjectID // Use project.ProjectID
        }).then(response => {
            if (!response.data.success) {
                console.error('Failed to generate metrics_factory.py');
                throw new Error('Failed to generate metrics_factory.py');
            } else {
                console.log("Metrics_factory.py generated successfully!");
                setMetricsFactoryResponse(response.data);
            }
        }).catch(error => {
            console.error('Error:', error);
            throw error;
        });
    };
    
    

    

    const displaySnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setAlertSeverity(severity);
        setOpenSnackbar(true);
    };

    const saveStepEight = async () => {
        const response = await api.post('/api/save_step_eight', {
            ProjectID: project.ProjectID
        });
        if (!response.data.success) {
            throw new Error('Failed to save step eight');
        }
    };



    const saveStepDetails = async (currentStep) => {
        const endTime = new Date();
        const startTime = new Date(stepStartTimes[currentStep]);
        const duration = new Date(endTime - startTime).toISOString().substring(11, 19);
    
        const stepData = {
            ProjectID: project.ProjectID,
            StepNumber: currentStep,
            StartTime: stepStartTimes[currentStep],
            EndTime: endTime.toISOString(),
            Duration: duration,
            StepStatus: 'Completed'            
        };
        await api.post('/api/save_step', stepData, { withCredentials: true });
    };
    
    

    const nextStep = async () => {
        setIsStepLoaded(false); // Add this line at the beginning of the nextStep function
        if (currentStep === 1) {
            setIsStepLoaded(true);
            if(!project || !project.ProjectCompany) {
                displaySnackbar("Please select a company before continuing", "warning");
                return;
            }
            
            try {
                await saveStepDetails(currentStep);
                await handleIndustryStep();
                displaySnackbar("Company saved successfully!", "success");
                setCurrentStep(currentStep + 1);
            } catch (error) {
                console.error('Error:', error);
                displaySnackbar(error.message || "An error occurred", "error");
            }
            return;
        }


        if (currentStep === 2) {
            setIsStepLoaded(true);
            if (!project || !project.ProjectJob) {
                displaySnackbar("Please select a job before continuing", "warning");
                return;
            } 
            try {
                await saveStepDetails(currentStep);
                await handleWorkStep();
                displaySnackbar("Job saved successfully!", "success");
                setCurrentStep(currentStep + 1);
            } catch (error) {
                console.error('Error:', error);
                displaySnackbar(error.message || "An error occurred", "error");
            }
            return;
        }

        if (currentStep === 3) {
            setIsStepLoaded(true);
            await handleIdeaStep();
            return;
        }

        if (currentStep === 4) {
            setIsStepLoaded(true); 
            setLoading(false); // Stop showing the circular progress spinner
            if (selectedMetricIndices.length === 0) {
                displaySnackbar("Please select at least one metric before continuing", "warning");
                return;
            }
            try {
                await saveStepDetails(currentStep);
                displaySnackbar("Metrics saved successfully!", "success");
                
        
                setCurrentStep(currentStep + 1);
            } catch (error) {
                console.error('Error:', error);
                displaySnackbar(error.message || "An error occurred", "error");
            }
            return;
        }

        
        if (currentStep === 5) {
            setIsStepLoaded(true);
            if (!project || !project.ProjectDocuments) {
                displaySnackbar("Please select documents before continuing", "warning");
                return;
            }
            try {
                await saveStepDetails(currentStep);

                displaySnackbar("Documents saved successfully!", "success");
                setCurrentStep(currentStep + 1);
            } catch (error) {
                console.error('Error:', error);
                displaySnackbar(error.message || "An error occurred", "error");
            }
            return;
        }
        
        if (currentStep === 6) {
            setIsStepLoaded(true);
            if (!project || !project.ProjectAI) {
                displaySnackbar("Please select a Slack channel before continuing", "warning");
                return;
            }
            try {
                await saveStepDetails(currentStep);

                displaySnackbar("Custom AI saved successfully!", "success");
                setCurrentStep(currentStep + 1);
            } catch (error) {
                console.error('Error:', error);
                displaySnackbar(error.message || "An error occurred", "error");
            }

            return;
        }
        
        if (currentStep === 7) { 
            setIsStepLoaded(true);
            if (!project || !project.ProjectSlackChannel) {
                displaySnackbar("Please select a task before continuing", "warning");
                return;
            }
            try {
                await saveStepDetails(currentStep);
                displaySnackbar("Slack channel successfully!", "success");
                setShowLoadingDetails(true);
                setCurrentStep(8);
                await saveStepEight();
                await performOperations(); // Call performOperations here
            } catch (error) {
                console.error('Error:', error);
                displaySnackbar(error.message || "An error occurred", "error");
            }
            return;
        }
        

    };
    

    const prevStep = () => {
        if (currentStep > 1) setCurrentStep(currentStep - 1);
    };

    const getTitleMap = () => ({
        1: `What type of company do you work for in the ${project?.Industry || 'selected'} industry?`,
        2: `What is your role at your ${project?.ProjectCompany || 'selected'} company?`,
        3: `How should the custom app help you in your ${project?.ProjectJob || 'selected'} role?`,
        4: `Please upload a CSV file. I'll think of some insights to include in your ${project?.ProjectIdea || 'app'} app.`,
        5: `Select two documents you analyze together as as ${project?.ProjectJob || 'employee'} at your ${project?.ProjectCompany || 'company'} company.`,
        6: `How should your custom AI analyze your ${project?.ProjectDocuments || 'documents'} documents?`,
        7: `Choose a Slack channel to receive updates from your ${project?.ProjectAI || 'AI'} AI.`,
        8: "Loading your project, this should take about 30 seconds...",
    });
    



    const saveProjectDetails = async () => {
        return api.post('/api/save_project', {
            projectID: project.ProjectID,
            Industry: project.Industry,
            ProjectName: project.ProjectIdea,
            ProjectCompany: project.ProjectCompany,
            ProjectCompanyDescription: project.ProjectCompanyDescription,
            ProjectJob: project.ProjectJob,
            ProjectJobDescription: project.ProjectJobDescription,
            ProjectIdea: project.ProjectIdea,
            ProjectIdeaDescription: project.ProjectIdeaDescription,
            ProjectDocuments: project.ProjectDocuments,
            ProjectDocumentDescriptions: project.ProjectDocumentDescriptions,
            ProjectAI: project.ProjectAI,
            ProjectAIDescription: project.ProjectAIDescription,
            ProjectSlackChannel: project.ProjectSlackChannel,
            ProjectSlackChannelDescription: project.ProjectSlackChannelDescription,
        });
    };


    const createNewDeployment = async () => {
        try {
            const response = await api.post('/api/create_new_deployment', { ProjectID: project.ProjectID, ProjectName: project.ProjectIdea });
            if (response.data.success) {
                setDeployment(response.data.deployment);  // Store the new deployment details in context
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            console.error("Failed to create new deployment:", error);
            setSnackbarMessage(error.message);
        }
    };

    const formatProjectName = async () => {
        if (project) {
            try {
                const response = await api.post('/api/format_project_name', {
                    ProjectID: project.ProjectID,
                    ProjectIdea: project.ProjectIdea
                });
                const updatedProject = response.data;
                setProject(prev => ({ ...prev, ProjectIdea: updatedProject.ProjectIdea, ProjectName: updatedProject.ProjectName }));
            } catch (error) {
                console.error("Failed to format project name:", error);
            }
        }
    };
    



    const performOperations = async () => {
        try {


            await saveProjectDetails();
            await formatProjectName();
            await createNewDeployment();
            await saveSelectedMetrics();
            await saveDocuments();

            await createMetricsPy();
            //await createMetricsFile();
            await createMetricsFactoryPy();


            const responseFormatUserMessage = await api.post('/api/format_user_message', {
                project_id: project.ProjectID,  // Use ProjectID from project context
            });
            
            console.log("Response from /api/format_user_message:", responseFormatUserMessage.data);
    
            const responseFormatSystemMessage = await api.post('/api/format_system_message', {
                project_id: project.ProjectID,  // Use ProjectID from project context
                projectAI: project.ProjectAI,
                projectAIDescription: project.ProjectAIDescription
            });
    
            const responseFormatSlack = await api.post('/api/format_slack_channel', {
                project_id: project.ProjectID,  // Use ProjectID from project context
                project_slack_channel: project.ProjectSlackChannel,
            });

            const responseMetricsDetails = await api.post('/api/generate_metrics_details', {
                projectID: project.ProjectID,
                industryName: project.Industry,
                ideaName: project.ProjectIdea,
                ideaDescription: project.ProjectIdeaDescription,
            });

        

            // Show a success message after all operations are done
            setSnackbarMessage("Project finalized successfully");
            setAlertSeverity("success");
            setOpenSnackbar(true);
            setProjectReady(true);
            
            // Return true if everything is successful
            return true;

        } catch (error) {
            console.error('Error in performOperations:', error);
            setSnackbarMessage("Failed to finalize the project");
            setAlertSeverity("error");
            setOpenSnackbar(true);

            // Return false in case of error
            return false;
        }
    };

    const stepComponents = [
        Industry,
        Works,
        Idea,
        UploadCSV,
        Documents,
        Task,
        Tools,
      ];



    const propsForStep = [
        {}, // For Industry component, currently no specific props
        {}, // For Works component, currently no specific props
        {}, // For Idea component, currently no specific props
        {
          metrics: projectMetrics,
          setMetrics: setProjectMetrics,
          selectedMetricIndices: selectedMetricIndices,
          setSelectedMetricIndices: setSelectedMetricIndices
        }, // For UploadCSV component
        {}, // For Documents component, currently no specific props
        {}, // For Tools component, currently no specific props
        {}  // For Task component, currently no specific props
      ];
      
    
      return (
        <div className="job-info-container">

            <div className="sidebar-container">
                <ProjectSummary 
                    openSnackbar={openSnackbar}
                    setOpenSnackbar={setOpenSnackbar}
                    snackbarMessage={snackbarMessage}
                    setSnackbarMessage={setSnackbarMessage}
                    currentStep={currentStep}
                />
            </div>

            <div className="content-container">
      
            {loading && <CircularProgress color="primary" style={{ margin: '20px auto' }} />}

            {isTitleVisible && setLoading &&
            
            <h2 className="dynamic-title">{getTitleMap()[currentStep]}</h2>}

            {isStepLoaded && (
                <button 
                    ref={continueButtonRef} 
                    className={`continue-button ${!selectedData ? 'disabled' : ''}`}
                    onClick={selectedData ? nextStep : null}>
                    Continue
                </button>
            )}

            {stepComponents.map((Component, index) => {
                if (currentStep === index + 1) {
                    return <Component key={index} {...propsForStep[index]} />;
                }
                return null;
            })}


            {currentStep === 8 && <LoadingDetails projectReady={projectReady} onProjectReadyClick={handleProjectReady} />}
            <Snackbar 
                open={openSnackbar} 
                autoHideDuration={3000} 
                onClose={() => setOpenSnackbar(false)}
            >
                <Alert onClose={() => setOpenSnackbar(false)} severity="success">
                    {snackbarMessage}
                </Alert>
            </Snackbar>
          </div>
        </div>
      );
};

export default BrainstormProject;
