// ViewProject.js

import React, { useEffect, useState, useContext, useRef } from 'react';
import api from './api';
import { useParams } from 'react-router-dom';
import './ViewProject.css';
import './ProjectOverviewCard';
import './ProjectOverviewCard.css';
import ConfigCard from './ConfigCard';
import { ProjectContext } from './ProjectContext';
import RunProjectCard from './RunProjectCard';
import ProjectSummary from './ProjectSummary';
import DeploymentSummary from './DeploymentSummary';
//import DeploymentSummary from './DeploymentSummary';



const ViewProject = () => {
    const { projectID } = useParams();
    const { files, setFiles, project, setProject, configs, setConfigs, snackbarMessage, setSnackbarMessage, projectMetrics, setProjectMetrics, deployment, setDeployment } = useContext(ProjectContext);
    const [error, setError] = useState(null);
    const [reloadKey, setReloadKey] = useState(Math.random());
    const [executionOutput, setExecutionOutput] = useState(null);
    const [isDeploying, setIsDeploying] = useState(false);
    const [hasRunStarted, setHasRunStarted] = useState(false);
    const [isMetricsExpanded, setMetricsExpanded] = useState(false);

    const [deploymentSteps, setDeploymentSteps] = useState([
        { title: 'Configuration Update', status: 'Not Started', message: 'Waiting to update configurations...', estimatedTime: 5 },
        { title: 'Save to S3', status: 'Not Started', message: 'Waiting to save to S3...', estimatedTime: 3 },
        { title: 'CodeBuild', status: 'Not Started', message: 'Waiting to run CodeBuild...', estimatedTime: 120 },
        { title: 'Project URL', status: 'Not Started', message: 'Waiting for project URL...', estimatedTime: 40 }
    ]);

    

    const deleteProject = async () => {
        try {
            const response = await api.post(`/api/delete_service`, { projectID: project.ProjectID });
            if (response.data.success) {
                setSnackbarMessage("Project deleted successfully!");
                // Handle other success logic here, like redirecting to a different page if needed
            } else {
                setSnackbarMessage("Failed to delete the project.");
            }
        } catch (error) {
            console.error("Failed to delete project:", error);
            setSnackbarMessage("Error occurred while deleting the project.");
        }
    };
    

    const updateProjectStatus = async (status) => {
        try {
            const response = await api.post(`/api/update_project_status`, { projectID: project.ProjectID, status }, { withCredentials: true });
            if (!response.data.success) {
                console.error("Failed to update project status:", response.data.message);
            } else {
                // Update the project context as well, if needed
                setProject(prev => ({ ...prev, ProjectStatus: status }));
            }
        } catch (error) {
            console.error("Error updating project status:", error);
        }
    };
    



    const FormatAI = async () => {
        try {
            const responseAI = await api.post(`/api/format_ai_html`, { projectID: project.ProjectID }, { withCredentials: true });
            if (responseAI.data.success) {
                console.log("AI formatted successfully:", responseAI.data.message);
            } else {
                throw new Error(responseAI.data.message);
            }
        } catch (error) {
            console.error("Failed to format AI:", error);
        }
    };

    const FormatMetrics = async () => {
        try {
            const responseMetrics = await api.post(`/api/format_metrics_html`, { projectID: project.ProjectID }, { withCredentials: true });
            if (responseMetrics.data.success) {
                console.log("Metrics formatted successfully:", responseMetrics.data.message);
            } else {
                throw new Error(responseMetrics.data.message);
            }
        } catch (error) {
            console.error("Failed to format metrics:", error);
        }
    };




    const FormatApp = async () => {
        try {
            const responseApp = await api.post(`/api/format_app`, { projectID: project.ProjectID }, { withCredentials: true });
            if (responseApp.data.success) {
                console.log("App formatted successfully:", responseApp.data.message);
            } else {
                throw new Error(responseApp.data.message);
            }
        } catch (error) {
            console.error("Failed to format app:", error);
        }
    };

    const FormatFiles = async () => {
        try {
            const responseFiles = await api.post(`/api/format_files`, { projectID: project.ProjectID }, { withCredentials: true });
            if (responseFiles.data.success) {
                console.log("Files formatted successfully:", responseFiles.data.message);
            } else {
                throw new Error(responseFiles.data.message);
            }
        } catch (error) {
            console.error("Failed to format files:", error);
        }
    };

    

    const updateConfigs = async () => {
        try {
            // Update configuration files
            const responseUpdateConfigs = await api.post(`/api/create_dynamic_configs`, { projectID: project.ProjectID }, { withCredentials: true });
            if (responseUpdateConfigs.data.success) {
                // Update deployment context
                setDeployment(prev => ({ ...prev, ParameterStoreName: responseUpdateConfigs.data.ParameterStoreName, ParameterStoreStatus: responseUpdateConfigs.data.ParameterStoreStatus, S3Status: responseUpdateConfigs.data.S3Status, DeploymentStatus: responseUpdateConfigs.data.DeploymentStatus }));
            } else {
                throw new Error(responseUpdateConfigs.data.message);
            }
        } catch (error) {
            console.error("Failed to update configuration files:", error);
        }
    };

    const runProject = async () => {
        setHasRunStarted(true);
        setIsDeploying(true);
        updateStepStatus('Configuration Update', 'In Progress', 'Updating configuration files...');
        
        try {
            
            // 1. Update configuration files
            await FormatAI();
            await FormatMetrics();
            await FormatApp();
            await FormatFiles();
            await updateConfigs();
            updateStepStatus('Configuration Update', 'Completed', 'Configurations updated successfully.');

    
            // 2. Save to S3
            updateStepStatus('Save to S3', 'In Progress', 'Saving to S3...');
            try {
                const responseSave = await api.post(`/api/save_to_s3`, { projectID: project.ProjectID }, { withCredentials: true });
                if (responseSave.data.success) {
                    // Update deployment context
                    setDeployment(prev => ({ ...prev, S3ZipFileName: responseSave.data.S3ZipFileName, S3Status: responseSave.data.S3Status, CodeBuildStatus: responseSave.data.CodeBuildStatus, DeploymentStatus: responseSave.data.DeploymentStatus }));
                    await updateProjectStatus('Deployment On-Going');
                } else {
                    await updateProjectStatus('Deployment Error');
                }
            }

            catch (error) {
                console.error("Failed to save to S3:", error);
                setExecutionOutput("Deployment process failed!");
                setSnackbarMessage(error.message);
            } finally {
                updateStepStatus('Save to S3', 'Completed', 'Saved to S3 successfully.');
            }


            //3. Run CodeBuild
            updateStepStatus('CodeBuild', 'In Progress', 'Running CodeBuild...');
            try {
                const responseBuild = await api.post(`/api/run_codebuild`, { projectID: project.ProjectID, userID: project.UserID, projectName: project.ProjectName }, { withCredentials: true });
                if (responseBuild.data.success) {
                    // Update deployment context
                    setDeployment(prev => ({ ...prev, DockerImageName: responseBuild.data.DockerImageName, DeploymentStatus: responseBuild.data.DeploymentStatus, CodeBuildStatus: responseBuild.data.CodeBuildStatus, EcsStatus: responseBuild.data.EcsStatus }));
                    await updateProjectStatus('Deployment On-Going');
                } else {
                    await updateProjectStatus('Deployment Error');
                }
            }

            catch (error) {
                console.error("Failed to run CodeBuild:", error);
                setExecutionOutput("Deployment process failed!");
                setSnackbarMessage(error.message);
            } finally {
                updateStepStatus('CodeBuild', 'Completed', 'CodeBuild completed successfully.');
            }



            // 4. Check ECS Service for deployment completion
            updateStepStatus('Project URL', 'In Progress', 'Checking ECS Service...');
            try {
                const ecsResponse = await api.post(`/api/check_ecs_service`, { projectID: project.ProjectID, userID: project.UserID, projectName: project.ProjectName }, { withCredentials: true });
                if (ecsResponse.data.success) {
                    const successMessage = (
                        <span>
                            Deployment completed successfully!
                            You can access your application here:
                            <a href={ecsResponse.data.url} target="_blank" rel="noopener noreferrer">
                                {ecsResponse.data.url}
                            </a>
                        </span>
                    );
                    await updateProjectStatus('Deployed');
                    // Update deployment context with the ECS URL
                    setDeployment(prev => ({ ...prev, DeploymentURL: ecsResponse.data.url, DeploymentStatus: ecsResponse.data.DeploymentStatus, EcsStatus: ecsResponse.data.EcsStatus }));
                    updateStepStatus('Project URL', 'Completed', `Deployment completed successfully. URL: ${ecsResponse.data.url}`);
                    
                } else {
                    throw new Error(ecsResponse.data.message);
                }
            }
            catch (error) {
                console.error("Failed to check ECS Service:", error);
                setExecutionOutput("Deployment process failed!");
                setSnackbarMessage(error.message);
            } finally {
                //updateStepStatus('Project URL', 'Completed', 'Deployment completed successfully. URL: `https://{project.ProjectName}.codebuddy.dev`');
            }


            
    
        } catch (error) {
            console.error("Failed to run project:", error);
            setExecutionOutput("Deployment process failed!");
            setSnackbarMessage(error.message);
        } finally {
            setIsDeploying(false);
        }
    };
    
    // Helper function to update the status of a step
    const updateStepStatus = (title, status, message) => {
        setDeploymentSteps(prevSteps => {
            return prevSteps.map(step => {
                if (step.title === title) {
                    return { ...step, status, message };
                }
                return step;
            });
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {


                const projectResponse = await api.get(`/api/projects/${projectID}`, { withCredentials: true });
                const project = projectResponse.data;
                setProject(project);

                const configResponse = await api.get(`/api/projects/${projectID}/configs`, { withCredentials: true });

                setConfigs(configResponse.data);  // Keep the configs as an array

                const metricsResponse = await api.get(`/api/projects/${projectID}/metrics`, { withCredentials: true });
                const projectMetrics = metricsResponse.data;
                setProjectMetrics(projectMetrics);  // Set the metrics using context function


                // New code for fetching deployment
                const deploymentResponse = await api.get(`/api/deployment/${projectID}`, { withCredentials: true });
                const deploymentData = deploymentResponse.data;
                setDeployment(deploymentData);  // Set the deployment using context function

            } catch (error) {
                setError('Failed to fetch project data. Please try again later.');
            }
        };

        fetchData();
    }, [projectID, reloadKey]);


    
    useEffect(() => {
        console.log("ViewProject component re-rendered.");
    });
    

    if (error) {
        return <p>{error}</p>;
    }

    if (!project) {
        return <p>Loading...</p>;
    }
    
   // console.log('Config values:', configs.map(config => config.ConfigValue));


    return (
        <div className="view-project">


            {/* Project Summary Section */}
            <div className="view-project-summary">
                <ProjectSummary project={project} />
            </div>

            {/* Run Project Section */}
            <section className="run-project-section">
                <RunProjectCard runProject={runProject} deploymentSteps={deploymentSteps} hasRunStarted={hasRunStarted} deleteProject={deleteProject} />
            </section>

            <section className="deployment-section">
                <DeploymentSummary/>
            </section>

    
        </div>
    );
    
    
};

export default ViewProject;