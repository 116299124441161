// AssistChatBot.js
import React, { useState, useEffect } from 'react';
import api from './api';
import { Button, TextField, Box, Paper, CircularProgress, Accordion, AccordionSummary, AccordionDetails, LinearProgress, Typography, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './AssistChatBot.css';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import { styled } from '@mui/system';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';






const CustomAccordion = styled(Accordion)(({ theme }) => ({
    margin: '10px 0',
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    borderRadius: '15px',
    '&:not(:last-child)': {
      borderBottom: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&.Mui-expanded': {
      margin: '10px 0',
    },
    '&.MuiAccordion-root:before': {
      height: '0px',
    },
    '&.MuiAccordion-root.Mui-expanded': {
      margin: '10px 0',
      backgroundColor: '#f1f1f1', // Set a background color when expanded
      borderRadius: '15px',
      border: '1px solid #1e88e5',

    },
    transition: 'background-color 0.3s', // Add a transition effect
  }));
  
  const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    backgroundColor: '#ffffff',
    borderBottom: '1px solid rgba(0, 0, 0, .3)',
    marginBottom: -1,
    minHeight: 56,
    padding: '0 24px',
    borderRadius: '15px',
    border: '0.5px solid #f1f1f1',
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2)',
    '&$expanded': {
      minHeight: 56,
    },
    '& .question': {
      fontWeight: '600',
    },
  }));
  
  const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    backgroundColor: '#ffffff', // Use a different color for the answer section
    flexDirection: 'column',
    padding: '16px', // Add some padding
    borderRadius: '15px',
    '& .answer': {
      fontWeight: '400',
    },
  }));
  









const AssistChatBot = ({ projectID, questions, answers }) => {
    const [inputValues, setInputValues] = useState([]);
    const [loading, setLoading] = useState(false);
    const [savedResponses, setSavedResponses] = useState(0);
    const messagesEndRef = React.useRef(null);
    const [savedAnswers, setSavedAnswers] = useState([]);
    const [loadingDelay, setLoadingDelay] = useState(false); // This state will control the delay



    useEffect(() => {
        // Check if all answers are loaded
        const allAnswersLoaded = answers.every(answer => answer !== undefined);
        setLoading(!allAnswersLoaded);
    }, [answers]);

    const handleInputChange = (index, newValue) => {
        setInputValues((prevValues) => {
            const newValues = [...prevValues];
            newValues[index] = newValue;
            return newValues;
        });
    };

    const handleSubmit = async (index, event) => {
        // if the event is defined, prevent form submission
        if (event) {
            event.preventDefault();
        }
        setLoading(true);
        const user_input = inputValues[index];
        const question = questions[index]; // Get the question directly from the questions state

        try {
            const res = await api.post('/api/save_response', {
                project_id: projectID,
                user_input: user_input,
                question: question['content'],
            });

            setInputValues(prev => {
                const newValues = [...prev];
                newValues[index] = ""; // Clear the input field
                return newValues;
            });

            setSavedResponses(prev => prev + 1);
            setSavedAnswers(prev => {
                const newAnswers = [...prev];
                newAnswers[index] = user_input; // Save the user input
                return newAnswers;
            });

        } catch (err) {
            console.error(err);
        }

        setLoading(false);
    };

    const handleKeyDown = (event, index) => {
        if (event.key === 'Enter') {
            handleSubmit(index, event);
        }
    }

      


    return (
        <Paper elevation={0} className="assist-chatbot-paper">
            <Box className="assist-chatbot-box">
                <div className="assist-chatbot-content">
                    <div className="counter-container">
                        <div className="counter">
                            <h3>CodeBuddy Assist</h3>
                            Responses saved: {savedResponses} / {questions.length}
                            <LinearProgress 
                                variant="determinate" 
                                value={savedResponses/questions.length*100}
                                sx={{marginTop: 1, marginBottom: 1, height: 10}}
                            />
                        </div>
                    </div>
                    <div className="assist-chatbot-accordion">
                        {questions?.map((message, idx) => (
                                message.role === 'system' && (
                                    <CustomAccordion key={idx}>
                                        <CustomAccordionSummary expandIcon={<ExpandMoreIcon />} id={`panel${idx}a-header`}>
                                            <Typography className="question">{message.content}</Typography>
                                            {savedAnswers[idx] && <CheckCircleOutlineIcon color="success" />}
                                        </CustomAccordionSummary>
                                        <CustomAccordionDetails style={{display: 'flex', flexDirection: 'column'}}>
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend">Choose an answer:</FormLabel>
                                                <RadioGroup name={`question${idx}`} value={inputValues[idx] || ''} onChange={e => handleInputChange(idx, e.target.value)} className="answer">
                                                    {answers[idx]?.options?.map((option, optIdx) => (
                                                        <FormControlLabel key={optIdx} value={option} control={<Radio />} label={option} />
                                                    ))}
                                                </RadioGroup>
                                            </FormControl>
                                            <TextField 
                                                fullWidth 
                                                variant="filled"
                                                multiline
                                                maxRows={3}
                                                value={inputValues[idx] || ''} 
                                                onKeyDown={event => handleKeyDown(event, idx)}
                                                onChange={e => handleInputChange(idx, e.target.value)} 
                                                className="cb-assist-input-field" 
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton onClick={() => handleSubmit(idx)} className="save-response-icon">
                                                            <SendIcon />
                                                        </IconButton>
                                                    ),
                                                }}
                                            />
                                            {savedAnswers[idx] && 
                                                <Box className="saved-response-box">
                                                    <Typography variant="caption">Saved response: {savedAnswers[idx]}</Typography>
                                                </Box>
                                            }
                                </CustomAccordionDetails>
                            </CustomAccordion>
                            )
                        ))}
                    </div>
                </div>
            </Box>
            <div ref={messagesEndRef} />
            {loading && <CircularProgress />}
        </Paper>
    );
    
};

export default AssistChatBot;