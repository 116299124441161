// ProjectContext.js

import React, { createContext, useEffect, useState } from 'react';

export const ProjectContext = createContext();

export const ProjectProvider = ({ children }) => {
    const [files, setFiles] = useState([]);
    const [project, setProject] = useState(null);
    const [configs, setConfigs] = useState([]);
    const [metrics, setMetrics] = useState([]);
    const [projectMetrics, setProjectMetrics] = useState(null);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [deployment, setDeployment] = useState(null);


    const updateProjectIndustry = (industry) => {
        setProject(prev => ({ ...prev, Industry: industry }));
    };
    
    const updateProjectCompany = (company, description) => {
        setProject(prev => ({ ...prev, ProjectCompany: company, ProjectCompanyDescription: description }));
    };
    
    const updateProjectJob = (job, description) => {
        setProject(prev => ({ ...prev, ProjectJob: job, ProjectJobDescription: description }));
    };
    
    const updateProjectIdea = (idea, description) => {
        setProject(prev => ({ ...prev, ProjectIdea: idea, ProjectIdeaDescription: description }));
    };

    const updateProjectMetrics = (index, updatedMetric) => {
        setMetrics(prevMetrics => {
            const newMetrics = [...prevMetrics];
            newMetrics[index] = updatedMetric;
            return newMetrics;
        });
    };
    
    const updateProjectDocuments = (documents, description) => {
        setProject(prev => ({ ...prev, ProjectDocuments: documents, ProjectDocumentDescriptions: description }));
    };
    
    const updateProjectAI = (ai, description) => {
        setProject(prev => ({ ...prev, ProjectAI: ai, ProjectAIDescription: description }));
    };
    
    const updateProjectSlackChannel = (slackChannel, description) => {
        setProject(prev => ({ ...prev, ProjectSlackChannel: slackChannel, ProjectSlackChannelDescription: description }));
    };

    const updateProjectStatus = (status) => {
        setProject(prev => ({ ...prev, ProjectStatus: status }));
    };
    

    // Inside ProjectProvider in ProjectContext.js
    const resetProject = () => {
        setFiles([]);
        setProject(null);
        setConfigs([]);
        setMetrics([]);
        setProjectMetrics(null);
        setSnackbarMessage("");
    };

    useEffect(() => {
        console.log("Deployment:", deployment);
    }
    , [deployment]);

    

    return (
        <ProjectContext.Provider value={{ 
            files, setFiles, 
            project, setProject, 
            configs, setConfigs, 
            snackbarMessage, setSnackbarMessage, 
            metrics, setMetrics, 
            projectMetrics, setProjectMetrics, 
            updateProjectIndustry, updateProjectCompany, updateProjectJob, updateProjectIdea, updateProjectMetrics, updateProjectDocuments, updateProjectAI, updateProjectSlackChannel, updateProjectStatus, resetProject, deployment, setDeployment }}>
            {children}
        </ProjectContext.Provider>
    );
};
