// ConfigCard.js

import React, { useState } from 'react';
import api from './api';
import './ConfigCard.css';
import SlackConfig from './SlackConfig';
import AiConfig from './AiConfig';
import { FaChevronUp, FaTimes } from 'react-icons/fa';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';


const ConfigCard = ({ configName, configValue, configStatus, configID, onSave, project, fileID, setSnackbarMessage, configs, setConfigs }) => {
    const [currentConfig, setCurrentConfig] = useState(configValue);
    const [isExpanded, setIsExpanded] = useState(false);
    const [notification, setNotification] = useState(null);

    // Create a mapping of the available configurations and their respective components
    const configComponents = {
        'SlackConfig': SlackConfig,
        'AiConfig': AiConfig
    };

    const configNameDisplayMapping = {
        'SlackConfig': 'Slack Settings',
        'AiConfig': 'AI Settings'
    };
    

    // This function is to close the notification
    const closeNotification = () => {
        setNotification(null);
    };

    const handleInputChange = (section, key, value) => {
        setCurrentConfig(prevConfig => ({
            ...prevConfig,
            [key]: value
        }));
    };

    
    const renderStatusIcon = () => {
        const statusTitle = configStatus === 'Confirmed' ? 'Confirmed' : 'Unconfirmed';
        const IconComponent = configStatus === 'Confirmed' ? CheckCircleIcon : ErrorIcon;
        const className = configStatus === 'Confirmed' ? 'config-status-icon-active' : 'config-status-icon-inactive';
    
        return (
            <Tippy content={statusTitle} arrow={true} animation="fade" duration={200}>
                <span>
                    <IconComponent title={statusTitle} className={className} />
                </span>
            </Tippy>
        );
    };
    
    

    const renderConfigInputs = () => {
        const ConfigComponent = configComponents[configName];
        return ConfigComponent ? <ConfigComponent configValue={currentConfig} onChange={handleInputChange} /> : null;
    };

    const handleSubmit = async () => {
        try {
            const response = await api.post('/api/save_config', {
                configName: configName,
                configValue: currentConfig,
                fileID: fileID
            });
    
            const responseConfigStatus = await api.post('/api/save_config_status', {
                configID: configID,
            });
    
            if (response.data.success) {
                setSnackbarMessage(response.data.message);
                onSave(configName, currentConfig);
    
                // Update the local state for configStatus to "Confirmed" without reloading
                if(responseConfigStatus.data.success) {
                    const updatedConfigs = [...configs];
                    const index = updatedConfigs.findIndex(cfg => cfg.ConfigID === configID);
                    if (index !== -1) {
                        updatedConfigs[index].ConfigStatus = "Confirmed";
                        setConfigs(updatedConfigs);
                    }
                }
    
                setNotification({ type: 'success', message: 'Configuration saved successfully!' });
                setTimeout(closeNotification, 5000);
            } else {
                setNotification({ type: 'error', message: `Failed to save config: ${response.data.message}` });
                setTimeout(closeNotification, 5000);
            }
        } catch (error) {
            setNotification({ type: 'error', message: 'Error saving the config. Please try again later.' });
            setTimeout(closeNotification, 5000);
        }
    };
    

    return (
        <div className="config-card">
            <div className="config-header" onClick={() => setIsExpanded(!isExpanded)}>
                <h4>
                <FaChevronUp className={`chevron-icon ${isExpanded ? 'rotated' : ''}`} />
                {configNameDisplayMapping[configName] || configName}
                </h4>

                <div className="config-status-icon">
                    {renderStatusIcon()}
                </div>
            </div>

            {isExpanded && (
                <>
                    <div className="config-items">
                        {renderConfigInputs()}
                    </div>
                    <button className="config-save-btn" onClick={handleSubmit}>Confirm Settings</button>
                    {notification && (
                        <div className={`config-notification ${notification.type}`}>
                            {notification.message}
                            <FaTimes className="close-notification" onClick={closeNotification} />
                        </div>
                    )}

                </>
            )}
        </div>
    );
};

export default ConfigCard;

