import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import './Home.css';

const Home = ({ isLoggedIn, setIsLoggedIn }) => {
    const navigate = useNavigate();  // New navigate instance

    // New function to handle creating a new project
    const handleNewProject = () => {
        navigate('/initialize_project');
    };

    if (isLoggedIn) {
        return (
            <div className="home-container">
                <Card className="text-center home-card">
                    <Card.Header>Welcome back!</Card.Header>
                    <Card.Body>
                        <Link to="/projects">
                            <Button variant="primary" className="home-button">View Projects</Button>
                        </Link>
                        <Button onClick={handleNewProject} variant="primary" className="home-button">Create New Project</Button>
                        <Button onClick={() => setIsLoggedIn(false)} variant="secondary" className="home-button">Log Out</Button>
                    </Card.Body>
                </Card>
            </div>
        );
    } else {
        return (
            <div className="home-container">
                <Card className="text-center home-card">
                    <Card.Header as="h4" className="home-title">Welcome to CodeBuddy</Card.Header>
                    <Card.Body>
                        <Link to="/signup">
                            <Button variant="primary" className="home-button">Sign Up</Button>
                        </Link>
                        <Link to="/login">
                            <Button variant="primary" className="home-button">Log In</Button>
                        </Link>
                    </Card.Body>
                </Card>
            </div>
        );
    }
};

export default Home;
