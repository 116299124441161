import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './ProjectCard.css';

const ExistingProjectCard = () => {
    const navigate = useNavigate();

    const handleStart = () => {
        navigate('/projects');
    };

    return (
        <Card className="existing-project-card">
            <Card.Body>
                <Card.Title>View Current Projects</Card.Title>
                <Button variant="primary" onClick={handleStart}>My Projects</Button>
            </Card.Body>
        </Card>
    );
};

export default ExistingProjectCard;
