import React, { useState, useEffect } from 'react';
import api from './api';
import './ProjectStepsCard.css';
import { Collapse, Button, ProgressBar, Card } from 'react-bootstrap';
import Tippy from '@tippyjs/react';
import { FaCheckCircle, FaExclamationCircle, FaAngleRight, FaAngleDown, FaTimesCircle} from 'react-icons/fa';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight } from 'react-syntax-highlighter/dist/cjs/styles/prism';


const ProjectStepsCard = ({ projectID }) => {
  const [projectSteps, setProjectSteps] = useState([]);
  const [projectSetup, setProjectSetup] = useState({});
  const [openDetails, setOpenDetails] = useState({});
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    fetchProjectStepsAndSetup();
  }, []);

  const [completedSteps, setCompletedSteps] = useState([]);

  const fetchProjectStepsAndSetup = async () => {
    try {
      const resSteps = await api.get(`/api/projects/${projectID}/steps`);
      setProjectSteps(resSteps.data);
  
      // Update completed steps based on StepStatus from the backend
      const completedStepIDs = resSteps.data
        .filter(step => step.StepStatus === 'Completed')
        .map(step => step.StepID);
      setCompletedSteps(completedStepIDs);
  
      const initialOpenDetails = {};
      resSteps.data.forEach(step => initialOpenDetails[step.StepID] = false);
      setOpenDetails(initialOpenDetails);
  
      const resSetup = await api.get(`/api/project-setup/${projectID}`);
      setProjectSetup(resSetup.data);
    } catch (err) {
      console.error(err);
    }
  };
  

  const renderCheckbox = (stepID) => {
    return (
      <label className="checkbox-container">
          <input
            type="checkbox"
            checked={completedSteps.includes(stepID)}
            onChange={() => completedSteps.includes(stepID) ? markStepIncomplete(stepID) : markStepComplete(stepID)}
          />
        <span className="checkmark"></span>
      </label>
    )
  };
  

  const toggleDetails = (stepID, index) => {
    setOpenDetails({...openDetails, [stepID]: !openDetails[stepID]});
    setCurrentStep(index);
  };

  const renderers = {
    code: ({node, inline, className, children, ...props}) => {
      const match = /language-(\w+)/.exec(className || '')
      return !inline && match ? (
        <SyntaxHighlighter 
          style={solarizedlight} 
          language={match ? match[1] : undefined} 
          PreTag="div" 
          children={String(children).replace(/\n$/, '')} 
          {...props}
        />
      ) : (
        <code className={className} {...props}>
          {children}
        </code>
      )
    }
  }

  const markStepComplete = async (stepID) => {
    try {
      const response = await api.post('/api/mark_step_complete', {
        step_id: stepID
      });
  
      if (response.data.status === 'success') {
        // Add stepID to completedSteps list
        setCompletedSteps([...completedSteps, stepID]);
  
        // Update StepStatus in projectSteps list
        setProjectSteps(projectSteps.map(step => 
          step.StepID === stepID ? {...step, StepStatus: 'Complete'} : step
        ));
  
        fetchTotalStepsCompleted(projectSetup.ProjectSetupID);
      } else {
        console.error(response.data.message);
      }
    } catch (err) {
      console.error(err);
    }
  };
  
  const markStepIncomplete = async (stepID) => {
    try {
      const response = await api.post('/api/mark_step_incomplete', {
        step_id: stepID
      });
  
      if (response.data.status === 'success') {
        // Remove stepID from completedSteps list
        setCompletedSteps(completedSteps.filter(id => id !== stepID));
  
        // Update StepStatus in projectSteps list
        setProjectSteps(projectSteps.map(step => 
          step.StepID === stepID ? {...step, StepStatus: 'Incomplete'} : step
        ));
  
        fetchTotalStepsCompleted(projectSetup.ProjectSetupID);
      } else {
        console.error(response.data.message);
      }
    } catch (err) {
      console.error(err);
    }
  };
  

  

  const fetchTotalStepsCompleted = async (projectSetupID) => {
    try {
      const response = await api.post('/api/total_steps_completed', {
        project_setup_id: projectSetupID
      });
      
      if (response.data.status === 'success') {
        // update state to trigger re-render and display new progress
        setProjectSetup({
          ...projectSetup,
          StepsCompleted: response.data.total_steps_completed
        });
      } else {
        console.error(response.data.message);
      }
    } catch (err) {
      console.error(err);
    }
  };
  


  const progressSteps = (projectSetup.StepsCompleted / projectSetup.StepsTotal) * 100;
  let variant = "success";
  if (progressSteps < 50) variant = "danger";
  else if (progressSteps < 80) variant = "warning";
  
  const tooltipContent = `${projectSetup.StepsCompleted} out of ${projectSetup.StepsTotal} steps completed.`;

  

  return (
    <Card className="project-steps-container">
      <Card.Body>
          <p className="progress-title">Setup Progress:</p>
          <Tippy content={`${projectSetup.StepsCompleted} out of ${projectSetup.StepsTotal} steps completed.`}>
            <ProgressBar className="step-progress-bar" variant={variant} now={(projectSetup.StepsCompleted / projectSetup.StepsTotal) * 100} label={`${Math.round((projectSetup.StepsCompleted / projectSetup.StepsTotal) * 100) || 0}%`} />
          </Tippy>
        <div className="project-steps-scroll">
          {projectSteps.map((projectStep, index) => (
            <div key={projectStep.StepID} className={`project-step ${currentStep === index ? 'current-step' : ''} ${completedSteps.includes(projectStep.StepID) ? 'completed-step' : ''}`}>
              <div className="step-status-container">
                {renderCheckbox(projectStep.StepID)}
                <p className={`step-status ${projectStep.StepStatus ? projectStep.StepStatus.toLowerCase() : 'unknown'}`}>
                  {projectStep.StepStatus || 'Unknown'}
                </p>
              </div>
              <div className="project-step-header" onClick={() => toggleDetails(projectStep.StepID, index)}>
                  <p className="project-step-display">
                    <span className="step-number">{index + 1}.</span>
                    {projectStep.StepName}
                  </p>
                {openDetails[projectStep.StepID] ? <FaAngleDown /> : <FaAngleRight />}
              </div>
              <Collapse in={openDetails[projectStep.StepID]}>
                <div className="project-step-description">
                  <ReactMarkdown 
                    remarkPlugins={[gfm]} 
                    components={renderers}
                    children={projectStep.StepDescription}
                  />
                </div>
              </Collapse>
            </div>
          ))}
        </div>
      </Card.Body>
    </Card>
  );
  
};

export default ProjectStepsCard;