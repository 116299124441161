//Industry.js
import React, { useState, useEffect, useRef, useContext } from 'react';
import './Industry.css';
import api from './api';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; 
import CircularProgress from '@mui/material/CircularProgress';
import BrainstormContext from './BrainstormContext';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { ProjectContext } from './ProjectContext';


const Industry = () => {
    const [industries, setIndustries] = useState([]);
    const [loadingIndustries, setLoadingIndustries] = useState(true);
    const [activeCardIndex, setActiveCardIndex] = useState(null);
    const [editingCardIndex, setEditingCardIndex] = useState(null);
    const [tempEditingName, setTempEditingName] = useState('');
    const [tempEditingDescription, setTempEditingDescription] = useState('');
    const { 
        selectedJob, 
        continueButtonRef,
        setSelectedData,
        setIsStepLoaded,
    } = useContext(BrainstormContext);

    const { project, setProject } = useContext(ProjectContext);
    const previousProjectRef = useRef();
    const previousJobRef = useRef();


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (continueButtonRef.current && continueButtonRef.current.contains(event.target)) {
                return;
            }
            if (!event.target.closest('.industry-card')) {
                setActiveCardIndex(null);
                setProject(prev => ({ ...prev, ProjectCompany: null, ProjectCompanyDescription: null }));
                setSelectedData(null);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, []);
    
    useEffect(() => {
        if (previousProjectRef.current !== project.Industry) {
            const fetchIndustries = async () => {
                try {
                    const response = await api.post('/api/generate_industry_description', {
                        projectID: project.ProjectID,
                        industryTitle: project.Industry,
                    });
                    setIndustries(response.data.new_description);
                    console.log('Industries Data:', response.new_description);
                } catch (error) {
                    console.error('Error fetching industries:', error);
                } finally {
                    setLoadingIndustries(false);
                }
            };
            fetchIndustries();
        }
        previousProjectRef.current = project.Industry;
        setIsStepLoaded(true);
    }, [project]);
    

    const handleCardClick = (index) => {
        if (activeCardIndex === index) {
            setActiveCardIndex(null);
            setProject(prev => ({ ...prev, ProjectCompany: null, ProjectCompanyDescription: null }));
            setSelectedData(null);
        } else {
            setActiveCardIndex(index);
            setProject(prev => ({ 
                ...prev, 
                ProjectCompany: industries[index].name,
                ProjectCompanyDescription: industries[index].description
            }));
            setSelectedData(true)
        }
    };
    

    if (loadingIndustries) {
        return (
            <div className="industry-loading">
                <CircularProgress color="primary" style={{ margin: '20px', alignContent: 'center' }} />
            </div>
        );
    }

    const handleToggleEditMode = (index, industry) => {
        if (editingCardIndex === index) {
            setEditingCardIndex(null);
            setTempEditingName('');
            setTempEditingDescription('');

        } else {
            setEditingCardIndex(index);
            setTempEditingName(industry.name);
            setTempEditingDescription(industry.description);
        }
    };

    const handleSaveEdits = (index) => {
        industries[index].name = tempEditingName || industries[index].name;
        industries[index].description = tempEditingDescription || industries[index].description;
        setIndustries([...industries]);
        setEditingCardIndex(null);
        setTempEditingName('');
        setTempEditingDescription('');
    };



    return (
        <div className="industry-container">
            {industries && industries.map((industry, index) => (
                <div 
                    key={index} 
                    className={`industry-card ${activeCardIndex === index ? 'active' : ''} ${editingCardIndex === index ? 'editing' : ''}`} 
                    onClick={() => handleCardClick(index)}
                >
                    <div className="card-header">
                        {editingCardIndex === index ? 
                            <>
                                <input 
                                    value={tempEditingName || industry.name} 
                                    onChange={e => setTempEditingName(e.target.value)}
                                    onBlur={() => handleSaveEdits(index)}
                                    onKeyDown={e => {
                                        if (e.key === 'Enter') handleSaveEdits(index);
                                    }}
                                    onClick={e => e.stopPropagation()} // Prevent triggering the card's click event
                                />
                                <span className="edit-icons">
                                    <Tippy content="Save">
                                        <SaveIcon onClick={(e) => { e.stopPropagation(); handleSaveEdits(index); }} />
                                    </Tippy>
                                </span>
                            </> :
                            <>
                                <h4>{industry.name}</h4>
                                <Tippy content="Edit">
                                    <span onClick={(e) => {e.stopPropagation(); handleToggleEditMode(index, industry)}}>
                                        <EditIcon />
                                    </span>
                                </Tippy>
                            </>
                        }
                    </div>
                    {editingCardIndex === index ? 
                        <textarea 
                            value={tempEditingDescription || industry.description} 
                            onChange={e => setTempEditingDescription(e.target.value)}
                            onBlur={() => handleSaveEdits(index)}
                            onKeyDown={e => {
                                if (e.key === 'Enter') handleSaveEdits(index);
                            }}
                            onClick={e => e.stopPropagation()} // Prevent triggering the card's click event
                        /> :
                        <p>{industry.description}</p>
                    }
                </div>
            ))}
        </div>
    );
};

export default Industry;
