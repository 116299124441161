// BrainstormProvider.js
import React, { useState, useRef, useEffect } from 'react';
import BrainstormContext from './BrainstormContext';

const BrainstormProvider = ({ children }) => {
    const [currentStep, setCurrentStep] = useState(1);
    const [selectedIndustry, setSelectedIndustry] = useState(null);
    const [selectedJob, setSelectedJob] = useState(null);
    const [selectedTool, setSelectedTool] = useState(null);
    const [selectedIdea, setSelectedIdea] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [showContinueButton, setShowContinueButton] = useState(false);
    const continueButtonRef = useRef(null);
    const [JobID, setJobID] = useState(null);
    const [projectID, setProjectID] = useState(null);
    const [selectedTask, setSelectedTask] = useState(null);
    const [TemplateID, setTemplateID] = useState(null);
    const [projectName, setProjectName] = useState(null);
    const [projectSentence, setProjectSentence] = useState(null);
    const [fileInfo, setFileInfo] = useState([]);
    const [configInfo, setConfigInfo] = useState([]);
    const [modalStep, setModalStep] = useState(1);
    const [industryOptions, setIndustryOptions] = useState(['Recruiting', 'Ecommerce', 'Consulting', 'Marketing', 'Finance', 'Healthcare', 'Education', 'Real Estate', 'Technology', 'Hospitality', 'Transportation', 'Manufacturing', 'Construction', 'Agriculture', 'Energy', 'Entertainment', 'Retail', 'Government', 'Non-Profit', 'Other']);
    const [selectedCompanyType, setSelectedCompanyType] = useState(null);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [selectedWork, setSelectedWork] = useState(null);
    const [metrics, setMetrics] = useState(null);
    const [selectedMetricIndices, setSelectedMetricIndices] = useState([]);
    const [ideas, setIdeas] = useState(null);
    const [metricsFactoryResponse, setMetricsFactoryResponse] = useState(null);
    const [selectedData, setSelectedData] = useState(null);
    const [isStepLoaded, setIsStepLoaded] = useState(false);


    useEffect(() => {
        console.log("Context state fileInfo:", fileInfo);
    }, [fileInfo]);

    useEffect(() => {
        console.log("Context state configInfo:", configInfo);
    }, [configInfo]);


    useEffect(() => {
        setSelectedData(null);
    }, [currentStep]);
    

    const updateSelectedIndustry = (updatedIndustry) => {
        setSelectedIndustry(updatedIndustry);
    };
    
    const updateSelectedJob = (updatedJob) => {
        setSelectedJob(updatedJob);
    };
    
    const updateSelectedTool = (updatedTool) => {
        setSelectedTool(updatedTool);
    };
    
    const updateSelectedIdea = (updatedIdea) => {
        setSelectedIdea(updatedIdea);
    };
    
    const updateSelectedWork = (updatedWork) => {
        setSelectedWork(updatedWork);
    };

    const updateSelectedDocument = (updatedDocument) => {
        setSelectedDocument(updatedDocument);
    };
    
    const updateSelectedTask = (updatedTask) => {
        setSelectedTask(updatedTask);
    };

    const updateMetrics = (index, updatedMetric) => {
        let updatedMetrics = [...metrics];
        updatedMetrics[index] = updatedMetric;
        setMetrics(updatedMetrics);
    };

    const resetCurrentStep = () => {
        setCurrentStep(1);
    }

    const resetSelectedIndustry = () => {
        setSelectedIndustry(null);
    }

    const resetIndustryOptions = () => {
        setIndustryOptions(['Recruiting', 'Ecommerce', 'Consulting', 'Marketing', 'Finance', 'Healthcare', 'Education', 'Real Estate', 'Technology', 'Hospitality', 'Transportation', 'Manufacturing', 'Construction', 'Agriculture', 'Energy', 'Entertainment', 'Retail', 'Government', 'Non-Profit', 'Other']);
    }

    

    return (
        <BrainstormContext.Provider value={{
            currentStep, setCurrentStep,
            selectedIndustry, setSelectedIndustry,
            selectedJob, setSelectedJob,
            openSnackbar, setOpenSnackbar,
            snackbarMessage, setSnackbarMessage,
            alertSeverity, setAlertSeverity,
            showContinueButton, setShowContinueButton,
            selectedTool, setSelectedTool,
            selectedIdea, setSelectedIdea,
            JobID, setJobID,
            projectID, setProjectID,
            continueButtonRef,
            selectedTask, setSelectedTask,
            TemplateID, setTemplateID,
            projectName, setProjectName,
            projectSentence, setProjectSentence,
            fileInfo, setFileInfo,
            configInfo, setConfigInfo,
            modalStep, setModalStep,
            industryOptions, setIndustryOptions,
            selectedCompanyType, setSelectedCompanyType,
            selectedDocument, setSelectedDocument,
            selectedWork, setSelectedWork,
            metrics, setMetrics,
            selectedMetricIndices, setSelectedMetricIndices,
            ideas, setIdeas,
            updateSelectedWork, updateSelectedIndustry, updateSelectedJob, updateSelectedTool, updateSelectedIdea, updateSelectedDocument, updateSelectedTask, updateMetrics,
            metricsFactoryResponse, setMetricsFactoryResponse,
            selectedData, setSelectedData,
            isStepLoaded, setIsStepLoaded, resetCurrentStep, resetSelectedIndustry, resetIndustryOptions
        }}>
            {children}
        </BrainstormContext.Provider>
    );
}

export default BrainstormProvider;

