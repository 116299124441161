// InitializeProjectCard.js
import React, { useState, useEffect, useRef } from 'react';
import { Card, Button, FormControl, InputLabel, OutlinedInput, CircularProgress, Paper, Box} from '@mui/material';
import { useLocation } from 'react-router-dom';
import api from './api';
import './InitializeProjectCard.css';
import Loading from './Loading';
import AssistChatBot from './AssistChatBot'; // Import AssistChatBot
import SendIcon from '@mui/icons-material/Send';
import ProjectStepsCard from './ProjectStepsCard';
import './StatusMessages.css';
import { Snackbar } from '@mui/material';






const InitializeProjectCard = () => {
    const [projectIdea, setProjectIdea] = useState("");
    const [loading, setLoading] = useState(false);
    const [projectInitialized, setProjectInitialized] = useState(false);  // New state variable
    const [loadingProgress, setProgress] = useState(0);
    const [progressMessage, setProgressMessage] = useState("");
    const [completedSteps, setCompletedSteps] = useState([]);
    const [projectName, setProjectName] = useState("");
    const [projectIdeaSubmitted, setProjectIdeaSubmitted] = useState(false);
    const [loadingQuestions, setLoadingQuestions] = useState(false);
    const projectIdeaInputRef = useRef();
    const [projectStarted, setProjectStarted] = useState(false);
    const [showBeginSetup, setShowBeginSetup] = useState(false);
    const [showProjectSteps, setShowProjectSteps] = useState(false);
    const increment = 100 / 18;  // Progress increment after each API call
    const [project_id, setProjectId] = useState(null);
    const [initialMessages, setInitialMessages] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [milestoneStatus, setMilestoneStatus] = useState(false);
    const [project_setup_id, setProjectSetupId] = useState(null); 
    const [showSnackbar, setShowSnackbar] = useState(false);



    // Focus on the input field when the form is displayed
    useEffect(() => {
        if (!projectIdeaSubmitted) {
            projectIdeaInputRef.current.focus();
        }
    }, [projectIdeaSubmitted]);


    const handleProjectIdeaSubmit = async (e) => {
        e.preventDefault();
        setLoadingQuestions(true);
    
        try {
            const response = await api.post('/api/initialize_project', {
                project_idea: projectIdea
            }, { withCredentials: true });
    
            // Update project_id:
            const projectId = response.data.project_id;
            const projectSetupId = response.data.project_setup_id;  // Get the project_setup_id here
            setProjectId(projectId);
            setProjectSetupId(projectSetupId);  // Set the project_setup_id state
    
            // Call the /api/codebuddy_questions API to get the initial messages
            const resQ = await api.post('/api/codebuddy_questions', { project_id: projectId });
            const initialMessages = resQ.data.initial_messages;
            setInitialMessages(initialMessages);
    
            // Call the /api/codebuddy_answers API to get the answers
            const resA = await api.post('/api/codebuddy_answers', { 
                project_id: projectId, 
                questions: initialMessages.map(msg => msg.content)
            });
            const answers = resA.data.answers;
            setAnswers(answers);
    
            setProjectIdeaSubmitted(true);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingQuestions(false);
        }
    };

    const handleGenerateBugs = async (e) => {

        try {

            setProgressMessage("Thinking about bugs...");
            const responseBugs = await api.post('/api/bug_sentences', {
                project_id: project_id
            }, { withCredentials: true });
            setProgress((oldProgress) => oldProgress + increment);  // Increment progress
            setCompletedSteps(prevSteps => [...prevSteps, "Generated bugs"]);

            console.log(responseBugs.data);

            setProgressMessage("Describing bugs...");
            const responseBugsDescription = await api.post('/api/generate_bugs', {
                project_id: project_id
            }, { withCredentials: true });
            setProgress((oldProgress) => oldProgress + increment);  // Increment progress
            setCompletedSteps(prevSteps => [...prevSteps, "Described bugs"]);

            console.log(responseBugsDescription.data);

        } catch (error) {
            console.error(error);
        }
    };

        
    
    

    const handleGenerateMilestones = async (e) => {

        try {

            setProgressMessage("Generating features...");
            const responseMilestones = await api.post('/api/generate_milestones', {
                project_id: project_id
            }, { withCredentials: true });


            console.log(responseMilestones.data);


            const responseMilestonesTotal = await api.post('/api/milestones_number', {
                project_id: project_id
            }, { withCredentials: true });
            setProgress((oldProgress) => oldProgress + increment);  // Increment progress
            setCompletedSteps(prevSteps => [...prevSteps, "Generated features"]);


            console.log(responseMilestonesTotal.data);

            

        } catch (error) {
            console.error(error);
        }
    };



    const handleSentenceName = async () => {
        try {

            setProgressMessage("Giving your project a name...");
            const responseSentence = await api.post('/api/project_sentence', {
                project_id: project_id
            }, { withCredentials: true });
    
            console.log(responseSentence.data);
    
            const responseName = await api.post('/api/project_name', {
                project_id: project_id
            }, { withCredentials: true });
    
            console.log(responseName.data);
            setProjectName(responseName.data.project_name);  // Set the projectName state
            setCompletedSteps(prevSteps => [...prevSteps, "Generated project name"]);

            setProgressMessage("Giving CodeBuddy documentation...");
            const responseDocs = await api.post('/api/doc_id', {
                project_id: project_id
            }, { withCredentials: true });

            console.log(responseDocs.data);
            setCompletedSteps(prevSteps => [...prevSteps, "Gave CodeBuddy documentation"]);
    
        } catch (error) {
            console.error(error);
        }
    };
    
    


    const handleFileMetadata = async () => {

        try {

            setProgressMessage("Generating file names...");
            const responseFileNames = await api.post('/api/generate_file_names', {
                project_id: project_id
            }, { withCredentials: true });
            setProgress((oldProgress) => oldProgress + increment);  // Increment progress
            setCompletedSteps(prevSteps => [...prevSteps, "Generated file names"]);

            console.log(responseFileNames.data);

            setProgressMessage("Generating file dependencies...");
            const responseFileDependencies = await api.post('/api/generate_file_dependencies', {
                project_id: project_id
            }, { withCredentials: true });
            setProgress((oldProgress) => oldProgress + increment);  // Increment progress
            setCompletedSteps(prevSteps => [...prevSteps, "Generated file dependencies"]);


            console.log(responseFileDependencies.data);

        } catch (error) {
            console.error(error);
        }
    };


    const handleSetup = async () => {
        try {
            setProgressMessage("Generating setup instructions...");
            const responseSetup = await api.post('/api/project_setup', {
                project_id: project_id
            }, { withCredentials: true });
            setProgress((oldProgress) => oldProgress + increment);  // Increment progress
            setCompletedSteps(prevSteps => [...prevSteps, "Generated setup instructions"]);

            console.log(responseSetup.data);
            if (!responseSetup.data.project_setup_id) {
                // Handle error: No project_setup_id in response
                console.error("No project_setup_id in response from /api/project_setup");
                return;
            }
            setProjectSetupId(responseSetup.data.project_setup_id); // update project_setup_id
    
            setProgressMessage("Thinking about setup steps...");
            const responseSteps = await api.post('/api/generate_project_steps', {
                project_id: project_id
            }, { withCredentials: true });            
            setProgress((oldProgress) => oldProgress + increment);  // Increment progress
            setCompletedSteps(prevSteps => [...prevSteps, "Generated setup steps"]);
            console.log(responseSteps.data);

            setProgressMessage("Generating step descriptions...");
            const responseTotalSteps = await api.post('/api/generate_total_steps', {
                project_setup_id: project_setup_id
            }, { withCredentials: true });
            console.log(responseTotalSteps.data);
            
    
            const responseStepDescriptions = await api.post('/api/step_descriptions', {
                project_setup_id: project_setup_id,
            }, { withCredentials: true });
            setProgress((oldProgress) => oldProgress + increment);  // Increment progress
            setCompletedSteps(prevSteps => [...prevSteps, "Generated step descriptions"]);


    
            console.log(responseStepDescriptions.data);



            setProgressMessage("Finalizing setup instructions...");
            const responseStepDisplays = await api.post('/api/step_displays', {
                project_setup_id: project_setup_id,
            }, { withCredentials: true });
            setProgress((oldProgress) => oldProgress + increment);  // Increment progress

    
            console.log(responseStepDisplays.data);
            setProgressMessage("You can now begin setting up your project");

            setShowBeginSetup(true);
    
        } catch (error) {
            console.error(error);
        }
    };
    
    

            
    const handleCodeRequirements = async () => {

        try {
            setProgressMessage("Generating code requirements...");
            const responseProjectRequirements = await api.post('/api/generate_project_requirements', {
                project_id: project_id
            }, { withCredentials: true });
            setProgress((oldProgress) => oldProgress + increment);  // Increment progress
            setCompletedSteps(prevSteps => [...prevSteps, "Generated code requirements"]);

            console.log(responseProjectRequirements.data);
            
            setProgressMessage("Generating file requirements...");
            const responseFileRequirements = await api.post('/api/generate_file_requirements', {
                project_id: project_id
            }, { withCredentials: true });
            setProgress((oldProgress) => oldProgress + increment);  // Increment progress
            setCompletedSteps(prevSteps => [...prevSteps, "Generated file requirements"]);

            console.log(responseFileRequirements.data);

        } catch (error) {
            console.error(error);
        }
    };

    const handleDataRequirements = async () => {

        try {
            setProgressMessage("Generating data requirements...");
            const responseProjectInputsOutputs = await api.post('/api/generate_inputs_outputs', {
                project_id: project_id
            }, { withCredentials: true });
            setProgress((oldProgress) => oldProgress + increment);  // Increment progress
            setCompletedSteps(prevSteps => [...prevSteps, "Generated data requirements"]);

            console.log(responseProjectInputsOutputs.data);

            setProgressMessage("Generating data examples...");
            const responseFileInputsOutputs = await api.post('/api/generate_file_inputs_outputs', {
                project_id: project_id
            }, { withCredentials: true });
            setProgress((oldProgress) => oldProgress + increment);  // Increment progress
            setCompletedSteps(prevSteps => [...prevSteps, "Generated data examples"]);


            console.log(responseFileInputsOutputs.data);

        } catch (error) {
            console.error(error);
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const response = await api.post('/api/check_use_case', { project_id: project_id });
    
            if (response.data.has_use_case) {
                setLoading(true);
                setProgress(1);
                console.log("Before setProjectInitialized:", projectInitialized);
                setProjectInitialized(true);
                setProjectStarted(true); // set the project as started here
                console.log("After setProjectInitialized:", projectInitialized); 
                performOperations();
            } else {
                setShowSnackbar(true);
            }
        } catch (error) {
            console.error(error);
        }
    };
    
    const handleBeginSetup = () => {
        setShowProjectSteps(true);
    };
    

    
    const handleGenerateBugsAndMilestones = async () => {
        await Promise.all([handleGenerateBugs(), handleGenerateMilestones()]);

        setCompletedSteps(prevSteps => [...prevSteps, "Done!"]);
        setMilestoneStatus(true);
        setProgress(100);
    };
    
      
    

    const STEPS = [
        { description: "Generating project description...", endpoint: '/api/generate_project_description', handler: 'handleSentenceName' },
        { description: "Generating project roadmap...", endpoint: '/api/codebuddy_clarify' },
        { description: "Setting up your files...", endpoint: '/api/generate_file_roadmap', handler: 'handleFileMetadata' },
        { description: "Defining steps and requirements...", endpoint: '/api/generate_project_dependencies', handler: ['handleSetup', 'handleCodeRequirements', 'handleDataRequirements'] },
        { description: "Deciding on the key features...", endpoint: '/api/file_sentences', handler: 'handleGenerateBugsAndMilestones' }
    ];

    const [steps, setSteps] = useState(STEPS.map(step => ({ ...step, status: 'pending' })));
    const [currentStep, setCurrentStep] = useState(0);

    const handlers = {
        handleSentenceName,
        handleFileMetadata,
        handleSetup,
        handleCodeRequirements,
        handleDataRequirements,
        handleGenerateBugsAndMilestones // New addition
    };
    
    
    const performOperations = async () => {
        try {
            for (let i = 0; i < STEPS.length; i++) {
                const step = STEPS[i];
                
                // Set the current step's status to 'ongoing'
                setSteps(prevSteps => prevSteps.map((s, idx) => 
                    idx === i ? { ...s, status: 'ongoing' } : s
                ));
    
                // Set the current progress message
                setProgressMessage(step.description);
        
                // Make the API call
                const response = await api.post(step.endpoint, { project_id: project_id }, { withCredentials: true });
                setProgress((oldProgress) => oldProgress + increment);  // Increment progress
                setCompletedSteps(prevSteps => [...prevSteps, step.description.replace('...', '')]);
                console.log(response.data);
        
                if (step.handler) {
                    if (Array.isArray(step.handler)) {
                        for (const handler of step.handler) {
                            await handlers[handler]();
                        }
                    } else {
                        await handlers[step.handler]();
                    }
                }
    
                // Set the current step's status to 'completed' after it's successful
                setSteps(prevSteps => prevSteps.map((s, idx) => 
                    idx === i ? { ...s, status: 'completed' } : s
                ));
            }
            setLoading(false);
        } catch (error) {
            console.error(error);
            
            // Set the current step's status to 'failed' if there's an error
            setSteps(prevSteps => prevSteps.map((s, idx) => 
                idx === currentStep ? { ...s, status: 'failed' } : s
            ));
            
            setCompletedSteps(prevSteps => [...prevSteps, "Done!"]);
            setLoading(false);
        }
    };
    
    
           
    
    return (
        projectStarted 
        ? <Loading 
            projectName={projectName} 
            projectID={project_id} 
            loadingProgress={loadingProgress} 
            setProgress={setProgress}
            message={progressMessage} 
            completedSteps={completedSteps} 
            showBeginSetup={showBeginSetup}
            handleBeginSetup={handleBeginSetup}
            showProjectSteps={showProjectSteps} 
            steps={steps} 
            milestoneStatus={milestoneStatus}

          />
        : <div className="initialize-project-card">
            <div className="form-and-chatbot-container">
                {projectIdeaSubmitted && initialMessages && answers
                    ? <AssistChatBot 
                        projectID={project_id}     
                        questions={initialMessages} 
                        answers={answers}               
                    />
                    : <form onSubmit={handleProjectIdeaSubmit} className="form-container">
                        <FormControl variant="outlined" className="form-field" fullWidth>
                            <InputLabel htmlFor="project-idea-input">Project Idea</InputLabel>
                            <OutlinedInput 
                                id="project-idea-input" 
                                value={projectIdea} 
                                onChange={e => setProjectIdea(e.target.value)} 
                                label="Project Idea" 
                                inputRef={projectIdeaInputRef} 
                            />
                        </FormControl>
                        <Button 
                            type="submit" 
                            variant="contained" 
                            color="primary" 
                            className="submit-button"
                            endIcon={<SendIcon />}
                        >
                            Submit
                        </Button>
                    </form>
                }
                {projectIdeaSubmitted && 
                    <div className="start-project-button-container">
                        <Button variant="contained" onClick={handleSubmit} className="start-project-button">
                            Start Project
                        </Button>
                    </div>
                }
            </div>
            {projectInitialized
                ? <Loading 
                    projectName={projectName} 
                    projectID={project_id} 
                    loadingProgress={loadingProgress} 
                    setProgress={setProgress}
                    message={progressMessage} 
                    completedSteps={completedSteps} 
                    showBeginSetup={showBeginSetup}
                    handleBeginSetup={handleBeginSetup}
                    showProjectSteps={showProjectSteps}
                    steps={steps}
                    milestoneStatus={milestoneStatus}

                  />
                : null
            }
    
            {loadingQuestions && <CircularProgress />}
            <Snackbar
            open={showSnackbar}
            autoHideDuration={6000}
            onClose={() => setShowSnackbar(false)}
            message="You must generate a use case before proceeding (see 🚀 button top right)"
            action={
                <Button color="primary" size="small" onClick={() => setShowSnackbar(false)}>
                    Close
                </Button>
            }
        />
    </div>
);
    
    
    
};

export default InitializeProjectCard;