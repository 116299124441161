import React, { useState, useEffect } from 'react';
import api from './api';
import { Button, TextField, Box, Paper, CircularProgress } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import './ChatBot.css';
import ReactMarkdown from 'react-markdown';  // Import react-markdown
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';  // Import SyntaxHighlighter
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';  // Import the solarizedlight theme
import gfm from 'remark-gfm';  // Import gfm plugin



const TypingText = ({ text, role }) => {
    const [displayedText, setDisplayedText] = useState('');

    useEffect(() => {
        setDisplayedText(text);
    }, [text]);

    return (
        <ReactMarkdown 
            remarkPlugins={[gfm]}  // Add this line
            components={{
                code({node, inline, className, children, ...props}) {
                    const match = /language-(\w+)/.exec(className || '')
                    const isBlock = children.includes('\n');
                    return (!inline && match) || isBlock ? (
                        <Box>
                            <SyntaxHighlighter 
                                style={solarizedlight} 
                                language={match ? match[1] : "python"} 
                                PreTag="div" 
                                children={String(children).replace(/\n$/, '')} 
                                {...props}
                            />
                            <Button 
                                variant="outlined" 
                                onClick={() => navigator.clipboard.writeText(String(children).replace(/\n$/, ''))}
                            >
                                Copy
                            </Button>
                        </Box>
                    ) : (
                        <code className={className} {...props}>
                            {children}
                        </code>
                    )
                }
            }}
        >
            {displayedText}
        </ReactMarkdown>
    );
};


const ChatBot = ({ projectId }) => {
    const [conversation, setConversation] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const res = await api.get(`/api/projects/${projectId}`); // Fetch the project data from API
            const project = res.data;
            const initialMessage = `H i, I'm CodeBuddy and I'm here to help you set up ${project.ProjectName}. ${project.ProjectSentence} How can I help you?`;
            setConversation([{ role: 'system', content: initialMessage }]); // Set the initial conversation
        };
        fetchData();
    }, [projectId]);

    const handleSubmit = async () => {
        setLoading(true);
        setConversation([...conversation, { role: 'user', content: inputValue }]);
        setInputValue('');
        
        let botResponse;  // Declare botResponse here
        
        try {
            const res = await api.post('/api/codebuddy_assist', { 
                project_id: projectId, 
                user_input: inputValue, 
                conv_history: JSON.stringify(conversation) // Here
            });
            
            botResponse = res.data.chat_content;  // Assign the value to botResponse here
        } catch (err) {
            console.error(err);
            setConversation((prevConversation) => [...prevConversation, { role: 'system', content: 'Sorry, something went wrong. Please try again.' }]);
        }
        
        if (botResponse) {
            setConversation((prevConversation) => [...prevConversation, { role: 'system', content: botResponse }]);
        } else {
            // handle the error, e.g. add an error message to your conversation
            setConversation((prevConversation) => [...prevConversation, { role: 'system', content: 'An error occurred' }]);
        }
        
        setLoading(false);
    };
    
    

    return (
        <Paper elevation={3} className="chatbot-paper">
            <Box className="chatbot-box">
                {conversation.map((message, idx) =>
                    <div key={idx} align={message.role === 'user' ? 'right' : 'left'} className={message.role}>
                        <Box className="message-box">
                            <TypingText text={message.content} role={message.role} />
                        </Box>
                    </div>
                )}
                {loading && <CircularProgress />}
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between" className="input-area">
                <TextField fullWidth variant="outlined" value={inputValue} onChange={e => setInputValue(e.target.value)} />
                <Button variant="contained" onClick={handleSubmit} className="submit-button">
                    <SendIcon />
                </Button>
            </Box>
        </Paper>
    );
};

export default ChatBot;
