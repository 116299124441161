import React from 'react';
import InitializeProjectCard from './InitializeProjectCard';
import './InitializeProject.css';  // Import your custom CSS file

const InitializeProject = () => {
    return (
        <div className="initialize-project-container">
            <InitializeProjectCard />
        </div>
    );
};

export default InitializeProject;
