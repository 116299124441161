// AiConfig.js

import React from 'react';
import Tippy from '@tippyjs/react'; // Import Tippy
import 'tippy.js/dist/tippy.css'; // Ensure Tippy styles are imported
import './AiConfig.css';

const AiConfig = ({ configValue, onChange }) => {

    const handleInputChange = (event) => {
        const { name, value } = event.target;
    
        // Check if it's the MAX_TOKENS input and convert it to an integer
        const finalValue = name === "MAX_TOKENS" ? parseInt(value, 10) : value;
    
        onChange('AiConfig', name, finalValue);
    };
    

    return (
        <div className="ai-config-card">
            <Tippy content="Choose the model for your AI">
                <label>
                    Choose a model for your custom AI:
                    <select name="MODEL_NAME" value={configValue.MODEL_NAME || ''} onChange={handleInputChange}>
                        <option value="gpt-4-1106-preview">gpt-4-turbo-128k</option>
                        <option value="gpt-4">gpt-4</option>
                        <option value="gpt-3.5-turbo-16k">gpt-3.5-turbo-16k</option>
                        <option value="gpt-3.5-turbo">gpt-3.5-turbo</option>
                        {/* Add other models here, or even fetch dynamically if possible */}
                    </select>
                </label>
            </Tippy>

            <Tippy content="Customize your Own AI">
                <label>
                    What do you want your custom AI to do?
                    <textarea
                        name="SYSTEM_MESSAGE_TEMPLATE"
                        value={configValue.SYSTEM_MESSAGE_TEMPLATE || ''}
                        placeholder="Enter the system message template here"
                        onChange={handleInputChange}
                    />
                </label>
            </Tippy>

            <Tippy content="How many words should your AI respond with?">
                <label>
                    How long should your AI's response be?
                    <input
                        type="range"
                        name="MAX_TOKENS"
                        min="10"
                        max="2000"
                        value={configValue.MAX_TOKENS || 1000}
                        onChange={handleInputChange}
                    />
                    <span>{configValue.MAX_TOKENS || 1000}</span>
                </label>
            </Tippy>


        </div>
    );
};

export default AiConfig;