// Loading.js
import React, { useState, useEffect } from 'react';
import { Box, Typography, List, ListItem, ListItemText, ListItemIcon, Button, CircularProgress } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import './Loading.css';
import { ProgressBar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ProjectStepsCard from './ProjectStepsCard';
import './StatusMessages.css';


const Loading = ({ loadingProgress, setProgress, message, completedSteps, projectName, projectID, showBeginSetup, handleBeginSetup, showProjectSteps, milestoneStatus, steps }) => {
  const navigate = useNavigate(); 
  const [projectCreated, setProjectCreated] = useState(false);

  useEffect(() => {
    if (completedSteps.length === 4) { 
      setProjectCreated(true);
    }
  }, [completedSteps]);

  const handleViewProject = () => {
    navigate(`/${projectName}/${projectID}`);
  };

  return (
    <Box className="loading-container">
      <Typography variant="h5" className="loading-message">{message}</Typography>
      <ProgressBar className="progress-bar-custom" variant="info" now={loadingProgress} label={`${Math.round(loadingProgress)}%`} />
      {projectCreated && milestoneStatus && (
        <>
          <Typography variant="subtitle1" className="project-created-message">Your project is ready:</Typography>
          <Button className="view-project-button" variant="contained" color="primary" onClick={handleViewProject}>
            View Project
          </Button>
        </>
      )}
      {showProjectSteps && <ProjectStepsCard projectID={projectID} />}
      <ul className="status-messages">
          {steps.map((step, index) => (
              <li key={index} className={`status-message ${step.status}`}>
                  {step.status === 'completed' && <CheckCircleIcon className="status-icon" style={{ color: 'green', width: '20px', height: '20px' }} />}
                  {step.status === 'failed' && <ErrorIcon className="status-icon" style={{ color: 'red', width: '20px', height: '20px' }} />}
                  {step.status === 'ongoing' && <CircularProgress className="status-icon" style={{ color: 'blue', width: '20px', height: '20px' }} />}
                  {step.description}
              </li>
          ))}
      </ul>
      {showBeginSetup && (
        <>
          <Button className="begin-setup-button" variant="contained" color="primary" onClick={handleBeginSetup}>
            Start Setting Up Project
          </Button>
        </>
      )}
      <List className="completed-steps">
        {completedSteps.map((step, index) => (
          <ListItem key={index}>
            <ListItemIcon>
              <CheckCircleIcon color="success" />
            </ListItemIcon>
            <ListItemText primary={step} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default Loading;
