// BrainstormProgressBar.js

import React, { useContext } from 'react';
import BrainstormContext from './BrainstormContext';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; 
import './BrainstormProgressBar.css';

const BrainstormProgressBar = () => {
    const { currentStep } = useContext(BrainstormContext);
    const progress = (currentStep / 8) * 100;  // Here, we have 8 steps
    const tooltipContent = `${8 - currentStep} Steps Remaining`;

    return (
        <Tippy content={tooltipContent}>
            <div className="brainstorm-progress-bar">
                <div className="brainstorm-progress-fill" style={{ width: `${progress}%` }}></div>
                <span className="percentage-indicator">{Math.floor(progress)}%</span>
            </div>
        </Tippy>
    );
};

export default BrainstormProgressBar;
