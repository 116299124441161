//Login.js
import React, { useState } from 'react';
import api from './api';  // Import your axios instance
import { useNavigate } from 'react-router-dom';
import './Login.css';

const Login = ({ setIsLoggedIn }) => {
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await api.post('/api/login', { email, password }, { withCredentials: true });  // Use your axios instance
            if (response.data.success) {
                setIsLoggedIn(true);
                navigate("/projects");
            } else {
                setError('Invalid email or password');
            }
        } catch (error) {
            console.log(error);
            setError('An error occurred');
        }
    };
    
    

    return (
        <div className="login-container">
            <h2 className="login-title">Login to CodeBuddy</h2>
            <form onSubmit={handleSubmit} className="login-form">
                <label htmlFor="email" className="login-label">Email</label>
                <input id="email" type="email" className="form-control login-input" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email" />
                <label htmlFor="password" className="login-label">Password</label>
                <input id="password" type="password" className="form-control login-input" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Enter your password" />
                <a href="/forgot-password" className="forgot-password-link">Forgot password?</a>
                <button type="submit" className="btn btn-primary login-button">Log In</button>
                {error && <p className="login-error">{error}</p>}
            </form>
            <p className="signup-link">Don't have an account? <a href="/signup">Sign up</a></p>
        </div>
    );
};


export default Login;
