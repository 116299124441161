// LoadingDetails.js
import React, { useState, useEffect, useContext } from 'react';
import './LoadingDetails.css';
import CircularProgress from '@mui/material/CircularProgress';
import { Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ProjectContext } from './ProjectContext';

const LoadingDetails = ({ projectReady, onProjectReadyClick }) => {
    const { project } = useContext(ProjectContext);
    const [completedSteps, setCompletedSteps] = useState([]);

    const informativeMessages = [
        { title: `${project.ProjectIdea}`, message: `${project.ProjectIdeaDescription}`, completed: false },
        { title: `${project.ProjectCompany}`, message: `${project.ProjectCompanyDescription}`, completed: false },
        { title: `${project.ProjectJob}`, message: `${project.ProjectJobDescription}`, completed: false },
        { title: `${project.ProjectDocuments}`, message: `${project.ProjectDocumentDescriptions}`, completed: false },
        { title: `${project.ProjectAI}`, message: `${project.ProjectAIDescription}`, completed: false },
        { title: `${project.ProjectSlackChannel}`, message: `${project.ProjectSlackChannelDescription}`, completed: false },
    ];
    

    useEffect(() => {
        const messageInterval = setInterval(() => {
            if (completedSteps.length < informativeMessages.length) {
                let newSteps = [...completedSteps, informativeMessages[completedSteps.length]];
                newSteps[completedSteps.length].completed = true;
                setCompletedSteps(newSteps);
            }
        }, 4000);
    
        return () => {
            clearInterval(messageInterval);
        };
    }, [completedSteps]);


    useEffect(() => {
        if (projectReady && completedSteps.length !== informativeMessages.length) {
            setCompletedSteps(informativeMessages);
        }
    }, [projectReady]);
    

    return (
        <div className="loading-details-container">
            <div className="loading-details-message">
                <div className="step-counter">
                    {projectReady ? `${informativeMessages.length}/${informativeMessages.length}` : `${completedSteps.length}/${informativeMessages.length}`} steps completed
                </div>
                {!projectReady && (
                    <CircularProgress color="primary" className="loading-progress" />
                )}
                {projectReady && (
                    <Button 
                        className='project-ready-button'
                        onClick={() => onProjectReadyClick()}
                    >
                        Your Project is Ready
                    </Button>
                )}
            </div>
            <div className="completed-steps-container">
                {completedSteps.map((step, index) => (
                    <div className={`completed-step-card ${step.completed ? 'step-completed' : ''}`} key={index}>
                        <div className="step-number">{index + 1}</div>
                        <div className="completed-step-icon">
                            {step.completed && <CheckCircleIcon className="status-icon" />}
                        </div>
                        <div className="step-content">
                            <div className="step-title">{step.title}</div>
                            <div className="step-message">{step.message}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LoadingDetails;
