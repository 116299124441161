// UploadCSV.js
import React, { useContext, useState } from 'react';
import './UploadCSV.css';
import BrainstormContext from './BrainstormContext';
import api from './api';
import CircularProgress from '@mui/material/CircularProgress';
import { Button } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { FaClock, FaPercentage, FaDollarSign, FaBalanceScale, FaStar } from 'react-icons/fa';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { ProjectContext } from './ProjectContext';


const UploadCSV = ({ selectedMetricIndices, setSelectedMetricIndices, metrics, setMetrics }) => {
    const { setSnackbarMessage, setAlertSeverity, setOpenSnackbar, projectID, selectedIdea, setSelectedData } = useContext(BrainstormContext);
    const [loading, setLoading] = useState(false);
    const [metricsCount, setMetricsCount] = useState(0);
    const [editingMetricIndex, setEditingMetricIndex] = useState(null);
    const [editingMetricName, setEditingMetricName] = useState('');
    const [editingMetricFormula, setEditingMetricFormula] = useState('');
    const { projectMetrics, setProjectMetrics, project, setProject } = useContext(ProjectContext);



    const handleMetricClick = (index) => {
        let updatedSelectedMetricIndices;
        if (selectedMetricIndices.includes(index)) {
            updatedSelectedMetricIndices = selectedMetricIndices.filter(i => i !== index);
        } else {
            updatedSelectedMetricIndices = [...selectedMetricIndices, index];
        }
        setSelectedMetricIndices(updatedSelectedMetricIndices);
        
        // Update metricsCount
        setMetricsCount(updatedSelectedMetricIndices.length);
        
        // Update the selectedData based on whether any metric card is selected
        if (updatedSelectedMetricIndices.length > 0) {
            setSelectedData(true);
        } else {
            setSelectedData(null);
        }
    };
    
    
    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (!file) return;
    
        setLoading(true);
    
        const formData = new FormData();
        formData.append('csv', file);
        
        // Use projectID directly here since it's now defined at the top level
        formData.append('projectID', project.ProjectID);
    
        try {
            const response = await api.post('/api/upload_csv', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    
            if (response.data.success) {
                setSnackbarMessage("CSV uploaded successfully");
                setAlertSeverity("success");
                setOpenSnackbar(true);
            
                // Call the /api/update_column_mapping_config route here
                try {
                    const columnMappingResponse = await api.post('/api/update_column_mapping_config', { projectID: project.ProjectID });
                    if (!columnMappingResponse.data.message.includes('successfully')) {
                        throw new Error(columnMappingResponse.data.error || "Failed to update column mapping");
                    }
                } catch (error) {
                    console.error('Error updating column mapping:', error);
                    setSnackbarMessage(error.message || "Failed to update column mapping");
                    setAlertSeverity("error");
                    setOpenSnackbar(true);
                    setLoading(false);
                    return; // Exit early so the subsequent code isn't executed if this call fails
                }
                
                // New code to handle update_data_transformation_config
                try {
                    const dataTransformationResponse = await api.post('/api/update_data_transformation_config', { projectID: project.ProjectID });
                    if (!dataTransformationResponse.data.message.includes('successfully')) {
                        throw new Error(dataTransformationResponse.data.error || "Failed to update data transformation config");
                    }
                } catch (error) {
                    console.error('Error updating data transformation config:', error);
                    setSnackbarMessage(error.message || "Failed to update data transformation config");
                    setAlertSeverity("error");
                    setOpenSnackbar(true);
                    setLoading(false);
                    return; // Exit early so the subsequent code isn't executed if this call fails
                }
                
    
                // Call the /api/create_metrics route here
                try {
                    const metricsRequestPayload = {
                        projectID: project.ProjectID,
                        Industry: project.Industry,
                        ProjectCompanyDescription: project.ProjectCompanyDescription,
                        ProjectCompany: project.ProjectCompany,
                        ProjectJob: project.ProjectJob,
                        ProjectJobDescription: project.ProjectJobDescription
                    };
            
                    const metricsResponse = await api.post('/api/create_metrics', metricsRequestPayload);
                    if (metricsResponse.data.success) {
                        console.log("Received metrics:", metricsResponse.data.metrics);
                        setProjectMetrics(metricsResponse.data.metrics);
                        console.log("Metrics updated!");
                    } else {
                        throw new Error(metricsResponse.data.message);
                    }
                } catch (error) {
                    console.error('Error fetching metrics:', error);
                    setSnackbarMessage(error.message || "Failed to fetch metrics");
                    setAlertSeverity("error");
                    setOpenSnackbar(true);
                }
    
            } else {
                throw new Error(response.data.message);
            }
    
        } catch (error) {
            console.error('Error uploading CSV:', error);
            setSnackbarMessage(error.message || "Failed to upload CSV");
            setAlertSeverity("error");
            setOpenSnackbar(true);
        } finally {
            setLoading(false);
        }
    };


    const getMetricIcon = (metric) => {
        const metricNameAndDesc = metric.name + metric.description;
    
        if (/Time|Date/i.test(metricNameAndDesc)) {
            return <FaClock color="#007BFF" />;
        }
        if (/Rate|Percentage|Ratio/i.test(metricNameAndDesc)) {
            return <FaPercentage color="#007BFF" />;
        }
        if (/Cost/i.test(metricNameAndDesc)) {
            return <FaDollarSign color="#007BFF" />;
        }
        if (/Diversity|Gender/i.test(metricNameAndDesc)) {
            return <FaBalanceScale color="#007BFF" />;
        }
        if (/Quality|Performance/i.test(metricNameAndDesc)) {
            return <FaStar color="#007BFF" />;
        }
    
        return null; // default fallback if none of the categories match
    };

    const handleToggleEditMetricMode = (index, metric) => {
        if (editingMetricIndex === index) {
            setEditingMetricIndex(null);
            setEditingMetricName('');
            setEditingMetricFormula('');
        } else {
            setEditingMetricIndex(index);
            setEditingMetricName(metric.name);
            setEditingMetricFormula(metric.formula);
        }
    };
    
    const handleSaveMetricEdits = (index) => {
        metrics[index].name = editingMetricName;
        metrics[index].formula = editingMetricFormula;
        setMetrics([...metrics]);
        setEditingMetricIndex(null);
        setEditingMetricName('');
        setEditingMetricFormula('');
    };

    console.log('Project Context in UploadCSV.js:', project);
    console.log('Project Metrics in UploadCSV.js:', projectMetrics);
    console.log('Metrics in UploadCSV.js:', metrics);
    console.log('Selected Metric Indices in UploadCSV.js:', selectedMetricIndices);


    
    return (
        <div className="csv-upload-container">
            {loading ? (
                <div className="industry-loading">
                    <CircularProgress color="primary" style={{ margin: '20px auto' }} />
                    <Typography variant="body1" gutterBottom>Brainstorming your metrics…</Typography>
                </div>
            ) : (
                <>
                    {!metrics && (
                        <div className="csv-upload-section">
                            <input 
                                type="file" 
                                accept=".csv" 
                                id="csv-upload" 
                                className="csv-input" 
                                onChange={handleFileChange}
                            />
                            <Tippy content={`Upload a CSV file, and CodeBuddy will help brainstorm some metrics to include in your project.`}>
                                <label htmlFor="csv-upload" className="csv-label">
                                    <Button variant="contained" component="span">
                                        Select CSV
                                    </Button>
                                </label>
                            </Tippy>
                        </div>
                    )}
                    {metrics && (
                        <>
                            <div className="metrics-header">
                                <div className="metrics-progress-container">
                                    <div className="metrics-progress-bar">
                                        <div className="metrics-progress-fill" style={{ width: `${(metricsCount/6)*100}%` }}></div>
                                    </div>
                                    <div className="metrics-count">{metricsCount}/6</div>
                                </div>
                                <div className="metrics-hint">Select up to 6 metrics (optional)</div>
                            </div>
    
                            <div className="metric-cards-container">
                                {metrics.map((metric, index) => (
                                    <Tippy key={index} content={metric.description} arrow={true} delay={100} placement="top">
                                        <Card 
                                            className={`metric-card ${selectedMetricIndices.includes(index) ? "selected" : ""}`} 
                                            onClick={() => handleMetricClick(index)}
                                        >
                                            <CardContent>
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                    {getMetricIcon(metric)}
                                                    {editingMetricIndex === index ? 
                                                        <input 
                                                            value={editingMetricName} 
                                                            onChange={e => setEditingMetricName(e.target.value)}
                                                            onBlur={() => handleSaveMetricEdits(index)}
                                                            onKeyDown={e => e.key === 'Enter' && handleSaveMetricEdits(index)}
                                                        /> :
                                                        <Typography variant="h6" component="div">{metric.name}</Typography>
                                                    }
                                                    <span onClick={(e) => {e.stopPropagation(); handleToggleEditMetricMode(index, metric)}}>
                                                        {editingMetricIndex === index ? <SaveIcon /> : <EditIcon />}
                                                    </span>
                                                </div>
                                                {editingMetricIndex === index ? 
                                                    <textarea 
                                                        value={editingMetricFormula} 
                                                        onChange={e => setEditingMetricFormula(e.target.value)}
                                                        onBlur={() => handleSaveMetricEdits(index)}
                                                        onKeyDown={e => e.key === 'Enter' && handleSaveMetricEdits(index)}
                                                    /> :
                                                    <Typography variant="body2" color="textSecondary">{metric.formula}</Typography>
                                                }
                                            </CardContent>
                                        </Card>
                                    </Tippy>
                                ))}
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );    
};

export default UploadCSV;