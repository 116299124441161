import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';

function NavigationBar({ isLoggedIn, setIsLoggedIn }) {
  const navigate = useNavigate();

  const handleNewProject = () => {
    navigate('/brainstorm_project');
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <Link to="/" className="nav-brand">CodeBuddy</Link>
        </Typography>
        { isLoggedIn ? (
            <>
            <Button color="inherit">
              <Link to="/projects" className="nav-link">Projects</Link>
            </Button>
            <Button color="inherit">
              <Link to="/account" className="nav-link">Account</Link>  {/* Add this line */}
            </Button>
            <Button color="inherit" onClick={() => setIsLoggedIn(false)}>
              Log Out
            </Button>
          </>
        ) : (
          <>
            <Button color="inherit">
              <Link to="/login" className="nav-link">Log In</Link>
            </Button>
            <Button color="inherit">
              <Link to="/signup" className="nav-link">Sign Up</Link>
            </Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default NavigationBar;
