// Task.js
import React, { useState, useEffect, useRef, useContext } from 'react';
import './Industry.css';
import api from './api';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; 
import CircularProgress from '@mui/material/CircularProgress';
import BrainstormContext from './BrainstormContext';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { ProjectContext } from './ProjectContext';

const Task = () => {
    const [tasks, setTasks] = useState([]);
    const [loadingTasks, setLoadingTasks] = useState(true);
    const [activeCardIndex, setActiveCardIndex] = useState(null);
    const previousProjectRef = useRef();
    const [editingCardIndex, setEditingCardIndex] = useState(null);
    const [tempEditingName, setTempEditingName] = useState('');
    const [tempEditingDescription, setTempEditingDescription] = useState('');
    const { continueButtonRef, setSelectedData } = useContext(BrainstormContext);
    const { project, setProject, projectMetrics } = useContext(ProjectContext);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (continueButtonRef.current && continueButtonRef.current.contains(event.target)) {
                return;
            }
    
            if (!event.target.closest('.industry-card')) {
                setActiveCardIndex(null);
                setProject(prev => ({ ...prev, ProjectAI: null, ProjectAIDescription: null }));
                setSelectedData(null);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, []);
    
    useEffect(() => {
        if (previousProjectRef.current !== project.Industry) {
            const fetchTasks = async () => {
                try {

                    // Extract metrics from projectMetrics
                    const metricsData = projectMetrics.map(metric => ({
                        name: metric.name,
                        description: metric.description,
                        formula: metric.formula
                    }));

                    const response = await api.post('/api/generate_tasks', {
                        projectID: project.ProjectID,
                        industryName: project.Industry,
                        industryDescription: project.ProjectCompanyDescription,
                        companyType: project.ProjectCompany,
                        jobName: project.ProjectJob,
                        jobDescription: project.ProjectJobDescription,
                        ideaName: project.ProjectIdea,
                        ideaDescription: project.ProjectIdeaDescription,
                        documentName: project.ProjectDocuments,
                        documentDescription: project.ProjectDocumentDescriptions,
                        metrics: metricsData,
                    });
                    setTasks(response.data.tasks);
                } catch (error) {
                    console.error('Error fetching tasks:', error);
                } finally {
                    setLoadingTasks(false);
                }
            };
            fetchTasks();
        }
        previousProjectRef.current = project.Industry;
    }, [project]);
    

    const handleCardClick = (index) => {
        if (activeCardIndex === index) {
            setActiveCardIndex(null);
            setProject(prev => ({ ...prev, ProjectAI: null, ProjectAIDescription: null }));
            setSelectedData(null);
        } else {
            setActiveCardIndex(index);
            setProject(prev => ({ 
                ...prev, 
                ProjectAI: tasks[index].name,
                ProjectAIDescription: tasks[index].description
            }));
            setSelectedData(true);
        }
    };

    const handleToggleEditMode = (index, task) => {
        if (editingCardIndex === index) {
            setEditingCardIndex(null);
            setTempEditingName('');
            setTempEditingDescription('');
        } else {
            setEditingCardIndex(index);
            setTempEditingName(task.name);
            setTempEditingDescription(task.description);
        }
    };

    const handleSaveEdits = (index) => {
        tasks[index].name = tempEditingName || tasks[index].name;
        tasks[index].description = tempEditingDescription || tasks[index].description;
        setTasks([...tasks]);
        setEditingCardIndex(null);
        setTempEditingName('');
        setTempEditingDescription('');
    };

    if (loadingTasks) {
        return (
            <div className="industry-loading">
                <CircularProgress color="primary" style={{ margin: '20px', alignContent: 'center' }} />
            </div>
        );
    }

    console.log('Project Context in Task.js:', project);

    return (
        <div className="industry-container">
            {tasks.map((task, index) => (
                <div 
                    key={index} 
                    className={`industry-card ${activeCardIndex === index ? 'active' : ''} ${editingCardIndex === index ? 'editing' : ''}`} 
                    onClick={() => handleCardClick(index)}
                >
                    <div className="card-header">
                        {editingCardIndex === index ? 
                            <>
                                <input 
                                    value={tempEditingName || task.name} 
                                    onChange={e => setTempEditingName(e.target.value)}
                                    onBlur={() => handleSaveEdits(index)}
                                    onKeyDown={e => {
                                        if (e.key === 'Enter') handleSaveEdits(index);
                                    }}
                                    onClick={e => e.stopPropagation()}
                                />
                                <span className="edit-icons">
                                    <Tippy content="Save">
                                        <SaveIcon onClick={(e) => { e.stopPropagation(); handleSaveEdits(index); }} />
                                    </Tippy>
                                </span>
                            </> :
                            <>
                                <h4>{task.name}</h4>
                                <Tippy content="Edit">
                                    <span onClick={(e) => {e.stopPropagation(); handleToggleEditMode(index, task)}}>
                                        <EditIcon />
                                    </span>
                                </Tippy>
                            </>
                        }
                    </div>
                    {editingCardIndex === index ? 
                        <textarea 
                            value={tempEditingDescription || task.description} 
                            onChange={e => setTempEditingDescription(e.target.value)}
                            onBlur={() => handleSaveEdits(index)}
                            onKeyDown={e => {
                                if (e.key === 'Enter') handleSaveEdits(index);
                            }}
                            onClick={e => e.stopPropagation()}
                        /> :
                        <p>{task.description}</p>
                    }
                </div>
            ))}
        </div>
    );
};

export default Task;
