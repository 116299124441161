// SlackConfig.js

import React from 'react';
import Tippy from '@tippyjs/react'; // Import Tippy
import 'tippy.js/dist/tippy.css'; // Ensure Tippy styles are imported
import './SlackConfig.css';

const SlackConfig = ({ configValue, onChange }) => {

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        onChange('SlackConfig', name, value);
    };

    return (
        <div className="slack-config-card">
        
            <Tippy content="You can find your token at: api.slack.com">

                <label>
                    Slack Bot Token
                    <input
                        type="text"
                        name="BOT_TOKEN"
                        value={configValue.BOT_TOKEN || ''}
                        placeholder="Enter your Slack Bot Token here"
                        onChange={handleInputChange}
                    />
                </label>
            </Tippy>

            <Tippy content="Where should your AI send notifications?">
                <label>
                    Slack Channel
                    <input
                        type="text"
                        name="CHANNEL"
                        value={configValue.CHANNEL || ''}
                        placeholder="Enter your Slack Channel"
                        onChange={handleInputChange}
                    />
                </label>
            </Tippy>

        </div>
    );
};

export default SlackConfig;
