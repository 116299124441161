// BrainstormSideBar.js

import React, { useContext, useState, useRef } from 'react';
import BrainstormContext from './BrainstormContext';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import './BrainstormSideBar.css';
import { FaClock, FaPercentage, FaDollarSign, FaBalanceScale, FaStar} from 'react-icons/fa';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import BusinessIcon from '@mui/icons-material/Business';
import ApartmentIcon from '@mui/icons-material/Apartment';
import WorkIcon from '@mui/icons-material/Work';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import BarChartIcon from '@mui/icons-material/BarChart';
import DescriptionIcon from '@mui/icons-material/Description';
import MemoryIcon from '@mui/icons-material/Memory';
import BuildIcon from '@mui/icons-material/Build';
import { ProjectContext } from './ProjectContext';



const BrainstormSideBar = () => {
    console.log("BrainstormSideBar rendered");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const nameRef = useRef(null);
    const descriptionRef = useRef(null);
    const formulaRef = useRef(null);
    const [isModalEditable, setIsModalEditable] = useState(false);
    const [currentEditingItem, setCurrentEditingItem] = useState(null);
    const [editingMetricIndex, setEditingMetricIndex] = useState(null);
    const [isSaved, setIsSaved] = useState(false);
    const {
        currentStep,
    } = useContext(BrainstormContext);

    const {
        project,
        projectMetrics,
        updateProjectIndustry,
        updateProjectCompany,
        updateProjectJob,
        updateProjectIdea,
        updateProjectMetrics,
        updateProjectDocuments,
        updateProjectAI,
        updateProjectSlackChannel
    } = useContext(ProjectContext);
        // Use project.ProjectName, project.Industry, etc. wherever needed
    

    const tooltipContents = [
        { title: "Industry Name", description: "Industry Description" },
        { title: "Type of company you work for", description: "Description of your company" },
        { title: "Your job title", description: "Your job responsibilities & tasks" },
        { title: "Name of your customized application", description: "What will the application help you do?" },
        { title: "Metric Name", description: "Metric Description" },
        { title: "Document Names", description: "How do you analyze the documents together?" },
        { title: "Slack Channel Name", description: "Description of notifications you want in the channel" },
        { title: "Task Name", description: "Task Description" },
    ];

    const handleItemClick = (content, index) => {
        if (index >= currentStep) return; // prevent opening if the current step is less than the clicked item
        setModalContent(content);
        setCurrentEditingItem(index);  // Set the index instead of displayName
        setIsModalOpen(true);
    };

    

    // Add function to handle the save button click
    const handleSaveClick = () => {
        const newName = nameRef.current ? nameRef.current.value : "";
        const newDescription = descriptionRef.current ? descriptionRef.current.value : "";
        const newFormula = formulaRef.current ? formulaRef.current.value : "";

        switch (currentEditingItem) {
            case 0:  // "Your Industry"
                updateProjectIndustry(newName);
                break;
            case 1:  // "Your Company Type"
                updateProjectCompany(newName, newDescription);
                break;
            case 2:  // "Your Job Details"
                updateProjectJob(newName, newDescription);
                break;
            case 3:  // "Your Project Idea"
                updateProjectIdea(newName, newDescription);
                break;

            case 4:  // "Metrics To Calculate"
                const updatedMetrics = [...projectMetrics];
                updatedMetrics[editingMetricIndex] = { name: newName, description: newDescription, formula: newFormula };
                updateProjectMetrics(updatedMetrics);
                break;


                
            case 5:  // "Your Document Analysis"
                updateProjectDocuments(newName, newDescription);
                break;
            case 6:  // "Your AI Integration"
                updateProjectAI(newName, newDescription);
                break;
            case 7:  // "Your Third-Party Tools"
                updateProjectSlackChannel(newName, newDescription);
                break;
            default:
                break;
        }
        
        setIsModalOpen(false);
        setIsModalEditable(false);
        setEditingMetricIndex(null);
        setIsSaved(true);
        setTimeout(() => setIsSaved(false), 2000); // Reset after 2 seconds
        setCurrentEditingItem(null);
    };
    
    
    const preDisplayNames = [
        "Your Industry",
        "Your Company",
        "Your Job Details",
        "Your Project Idea",
        "Your Data Analysis",
        "Your Documents",
        "Your AI Integration",
        "Your Third-Party Tools",
    ];

    const sideBarItems = [
        { displayName: preDisplayNames[0], label: "What industry are you in?", content: project ? project.Industry : null },
        {
            displayName: preDisplayNames[1],
            icon : <BusinessIcon />, 
            label: "What type of company do you work for?",
            content: project && project.ProjectCompany ? `${project.ProjectCompany}\n${project.ProjectCompanyDescription}` : null
        },
        {
            displayName: preDisplayNames[2],
            icon : <WorkIcon />,
            label: "What is your job title?",
            content: project && project.ProjectJob ? `${project.ProjectJob}\n${project.ProjectJobDescription}` : null
        },
        {
            displayName: preDisplayNames[3],
            icon : <LightbulbIcon />,
            label: "What should your custom application help you do?",
            content: project ? `${project.ProjectIdea}\n${project.ProjectIdeaDescription}` : null
        },
        { 
            displayName: preDisplayNames[4], 
            icon : <BarChartIcon />,
            label: "What metrics are relevant to your data?", 
            content: projectMetrics && projectMetrics.length ? projectMetrics.map(metric => "Name: " + metric.name + "\nDescription: " + metric.description + "\nFormula: " + metric.formula).join("\n\n") : null
        },
        {
            displayName: preDisplayNames[5],
            icon: <DescriptionIcon />,
            label: "What are two documents that you analyze together?",
            content: project ? `${project.ProjectDocuments}\n${project.ProjectDocumentDescriptions}` : null
        },
        {
            displayName: preDisplayNames[6],
            icon: <MemoryIcon />,
            label: "How should your custom AI help you?",
            content: project ? `${project.ProjectAI}\n${project.ProjectAIDescription}` : null
        },
        {
            displayName: preDisplayNames[7],
            icon: <BuildIcon />,
            label: "What Slack channel should your project connect to?",
            content: project ? `${project.ProjectSlackChannel}\n${project.ProjectSlackChannelDescription}` : null
        }
    ];
    
    
    
    const getMetricIcon = (metric) => {
        const metricNameAndDesc = metric.name + metric.description;
    
        if (/Time|Date/i.test(metricNameAndDesc)) {
            return <FaClock color="#007BFF" />;
        }
        if (/Rate|Percentage|Ratio/i.test(metricNameAndDesc)) {
            return <FaPercentage color="#007BFF" />;
        }
        if (/Cost/i.test(metricNameAndDesc)) {
            return <FaDollarSign color="#007BFF" />;
        }
        if (/Diversity|Gender/i.test(metricNameAndDesc)) {
            return <FaBalanceScale color="#007BFF" />;
        }
        if (/Quality|Performance/i.test(metricNameAndDesc)) {
            return <FaStar color="#007BFF" />;
        }
    
        return null; // default fallback if none of the categories match
    };

    console.log("BrainstormSideBar projectMetrics:", projectMetrics);
    console.log("BrainstormSideBar editingMetricIndex:", editingMetricIndex);

    const handleCloseModal = () => {
        const modalContent = document.querySelector('.brainstorm-modal-content');
        modalContent.classList.add('hide');
        setTimeout(() => {
            setIsModalOpen(false);
            modalContent.classList.remove('hide');
        }, 300);
    }
    

    return (
        <div className="brainstorm-sidebar">
            <div className="sidebar-items-container">
                {sideBarItems.map((item, index) => (
                    <Tippy content={item.label} key={index}>
                        <div className={`sidebar-item ${currentStep === index ? 'current' : ''} ${currentStep > index - 1 ? 'completed' : ''} ${index >= currentStep ? 'no-data' : ''}`} onClick={() => handleItemClick(item.content, index)}>                        
                        <h3 className={index >= currentStep ? 'pre-display' : ''}>
                            {item.displayName}
                        </h3>
                        </div>
                    </Tippy>
                ))}
            </div>
            {isModalOpen && (
                <div className="brainstorm-modal">
                    <div className={`brainstorm-modal-content ${isModalOpen ? 'show' : ''}`}>
                        
                        <Tippy content="Close">
                            <span className="brainstorm-close-button" onClick={() => {
                                setIsModalEditable(false);
                                setIsModalOpen(false);
                                handleCloseModal();
                            }}>×</span>
                        </Tippy>

                        {
                            (currentEditingItem !== 4 || isModalEditable) && (  // Added the isModalEditable condition here
                                isModalEditable ? (
                                    <Tippy content="Save">
                                        <span className="modal-edit-icon" onClick={handleSaveClick}>
                                            <SaveIcon />
                                        </span>
                                    </Tippy>
                                ) : (
                                    <Tippy content="Edit">
                                        <span className="modal-edit-icon" onClick={() => setIsModalEditable(true)}>
                                            <EditIcon />
                                        </span>
                                    </Tippy>
                                )
                            )
                        }

                        <div className="modal-content-text">
                            {isModalEditable ? (
                                <div className="modal-editable-content">
                                    <input className="modal-edit-input" ref={nameRef} defaultValue={currentEditingItem === 4 ? projectMetrics[editingMetricIndex].name : modalContent.split("\n")[0]} placeholder="Name" />
                                    {currentEditingItem === 4 ? (
                                        <>
                                            <textarea className="modal-edit-textarea" ref={descriptionRef} defaultValue={projectMetrics[editingMetricIndex].description} placeholder="Description" />
                                            <textarea className="modal-edit-textarea" ref={formulaRef} defaultValue={projectMetrics[editingMetricIndex].formula} placeholder="Formula" />
                                        </>
                                    ) : (
                                        <textarea className="modal-edit-textarea" ref={descriptionRef} defaultValue={modalContent.split("\n")[1]} placeholder="Description" />
                                    )}
                                </div>
                            ) : (
                                currentEditingItem === 4 ? (
                                    projectMetrics.map((metric, index) => (
                                        <div key={index} className="metric-item">
                                            {editingMetricIndex === index ? (
                                                <>
                                                    <input className="modal-edit-input" ref={nameRef} defaultValue={metric.name} placeholder="Name" />
                                                    <textarea className="modal-edit-textarea" ref={descriptionRef} defaultValue={metric.description} placeholder="Description" />
                                                    <textarea className="modal-edit-textarea" ref={formulaRef} defaultValue={metric.formula} placeholder="Formula" />
                                                </>
                                            ) : (
                                                <>
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                        {getMetricIcon(metric)}
                                                        <strong>{metric.name}</strong>
                                                        <Tippy content="Edit">
                                                            <span className="modal-edit-icon" onClick={() => { 
                                                                setEditingMetricIndex(index); 
                                                                setIsModalEditable(true); 
                                                            }}>
                                                                <EditIcon />
                                                            </span>
                                                        </Tippy>
                                                    </div>
                                                    <p><em>Formula:</em> {metric.formula}</p>
                                                    <p><em>Description:</em> {metric.description}</p>
                                                </>
                                            )}
                                        </div>
                                    ))
                                ) : (
                                    <>
                                        <Tippy content={tooltipContents[currentEditingItem].title}>
                                            <p>{modalContent.split("\n")[0]}</p>
                                        </Tippy>
                                        <Tippy content={tooltipContents[currentEditingItem].description}>
                                            <p>{modalContent.split("\n")[1]}</p>
                                        </Tippy>
                                    </>
                                )
                            )}
                        </div>

                    </div>  
                </div>
            )}

        </div>
    );    
};
export default BrainstormSideBar;






